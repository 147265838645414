import React, { useState, useEffect, useContext } from "react";
import { updateTopic, createTopic } from "../../Services/Service.tsx";
import { LoggedInContext } from "../../Context/Context.tsx";
import { useTranslation } from "react-i18next";

const TopicModal = ({
  formDataObj,
  showModal,
  closeModal,
  getAllTopicsFunc,
  projectArr,
  projectIdBasedOnRole,
  showErrorModal,
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(formDataObj);
  const [validationError, setValidationError] = useState("");
  const specialCharsRegex = /[!@#$%^&*(),.?":{}|<>]/;
  const context = useContext(LoggedInContext);

  const closeModalFunc = () => {
    closeModal();
  };

  useEffect(() => {
    setFormData(formDataObj);
  }, [formDataObj]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: { value: value, isChanged: true },
    }));
  };

  const handleDropDownChange = (event) => {
    const { name, value } = event.detail;
    setFormData((prevData) => ({
      ...prevData,
      [name]: { value: value, isChanged: true },
    }));
    getProjectCode(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const updatedFormData = {};
    for (let key in formData) {
      if (formData.hasOwnProperty(key)) {
        updatedFormData[key] = {
          value: formData[key]["value"],
          isChanged: true,
        };
      }
    }
    setFormData(updatedFormData);
    if (formData.topicName.value && formData.projectId.value) {
      const topicData = {
        projectId: formData.projectId.value,
        topicDesc: formData.topicDesc.value,
      };
      if (formData.newOrEdit?.value == "edit") {
        const existingTopicData = {
          ...topicData,
          updatedBy: context.session.username,
          topicId: formData.topicId.value,
          topicName: formData.topicName.value,
        };
        //API call for update an existing role
        updateTopic(existingTopicData)
          .then((data: any) => {
            closeModal();
            getAllTopicsFunc(projectIdBasedOnRole);
          })
          .catch((err) => {
            if (err?.response?.status == 401) {
              context.setSession({
                ["username"]: "",
                ["loggedin"]: false,
                ["projectCode"]: [],
                ["userRole"]: "",
              });
              localStorage.clear();
              window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
            }
          });
      } else {
        const newTopicData = {
          ...topicData,
          createdBy: context.session.username,
          topicName: formData.topicName.value,
          status: true,
        };
        // API call for create new role
        createTopic(newTopicData)
          .then((data: any) => {
            closeModal();
            getAllTopicsFunc(projectIdBasedOnRole);
          })
          .catch((error: any) => {
            if (error?.response?.status == 401) {
              context.setSession({
                ["username"]: "",
                ["loggedin"]: false,
                ["projectCode"]: [],
                ["userRole"]: "",
              });
              localStorage.clear();
              window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
            }
            if (error?.response?.data?.status == 500) {
              showErrorModal(
                `${t("topic")} ${newTopicData.topicName} ${t("exists")}`
              );
            }
          });
      }
    } else {
      console.log("error");
    }
  };

  // get project code when project name is selected
  const getProjectCode = (projectId) => {
    if (projectId != "") {
      let value = projectArr.findIndex((x) => x.projectId == projectId);
      setFormData((prevData) => ({
        ...prevData,
        ["topicName"]: {
          value: value > -1 ? `${projectArr[value].projectCode}/` : "",
          isChanged: true,
        },
      }));
    }
  };

  return (
    <>
      <tds-modal
        selector="#topic-modal"
        id="topic-modal-id"
        size="xs"
        actions="static"
        prevent="true"
        ref={(element) =>
          element?.addEventListener("tdsClose", (event) => {
            closeModalFunc();
          })
        }
      >
        <span slot="body" style={{ paddingRight: "0px !important" }}>
          <div className="tds-u-justify-center tds-u-flex tds-u-p0 tds-u-mb2 tds-col-max-12 tds-col-xxlg-12 tds-col-xlg-12  tds-col-lg-12  tds-col-md-12  tds-col-sm-12 tds-col-xs-12 tds-u-textalign-start">
            <h5 className="tds-u-mt0">
              {formData.newOrEdit?.value == "edit"
                ? t("edit-topic")
                : t("create-topic")}
            </h5>
          </div>

          <div className="tds-u-mb2 tds-col-max-12 tds-col-xxlg-12 tds-col-xlg-12  tds-col-lg-12  tds-col-md-12  tds-col-sm-12 tds-col-xs-12 tds-u-textalign-start">
            <tds-dropdown
              default-value={formData?.projectId?.value}
              key={formData?.projectId?.value}
              name="projectId"
              disabled={formData?.newOrEdit?.value == "edit"}
              label={t("project")}
              label-position="outside"
              placeholder={t("select-option")}
              helper={
                !formData?.projectId?.value && formData?.projectId?.isChanged
                  ? t("select-project-error")
                  : ""
              }
              size="sm"
              open-direction="auto"
              error={
                !formData?.projectId?.value && formData?.projectId?.isChanged
              }
            >
              {projectArr &&
                projectArr.map((project) => (
                  <tds-dropdown-option value={project.projectId}>
                    {project.projectName}
                  </tds-dropdown-option>
                ))}
            </tds-dropdown>
          </div>

          <div className="tds-u-mb2 tds-u-mt1 tds-col-max-12 tds-col-xxlg-12 tds-col-xlg-12  tds-col-lg-12  tds-col-md-12  tds-col-sm-12 tds-col-xs-12 tds-u-textalign-start ">
            <tds-text-field
              type="text"
              size="sm"
              label={t("topic-name")}
              placeholder={t("enter-topic-name")}
              disabled={formData.newOrEdit?.value == "edit"}
              label-position="outside"
              value={formData.topicName.value}
              onInput={handleInputChange}
              name="topicName"
              helper={
                formData.topicName.isChanged && !formData.topicName.value
                  ? t("topic-name-error")
                  : ""
              }
              state={
                validationError ||
                (formData.topicName.isChanged && !formData.topicName.value)
                  ? "error"
                  : "success"
              }
            ></tds-text-field>
          </div>

          <div className="tds-u-mb2 tds-col-max-12 tds-col-xxlg-12 tds-col-xlg-12  tds-col-lg-12  tds-col-md-12  tds-col-sm-12 tds-col-xs-12 tds-u-textalign-start">
            <tds-textarea
              state="default"
              name="topicDesc"
              label={t("topic-desc")}
              helper=""
              label-position="outside"
              placeholder={t("enter-topic-desc")}
              value={formData.topicDesc.value}
              no-min-width
              onInput={handleInputChange}
            ></tds-textarea>
          </div>
        </span>
        <span slot="actions">
          <div className="tds-u-flex tds-u-gap1 tds-col-max-12 tds-col-xxlg-12 tds-col-xlg-12  tds-col-lg-12  tds-col-md-12  tds-col-sm-12 tds-col-xs-12">
            <tds-button
              data-dismiss-modal
              size="sm"
              text={t("cancel")}
              onClick={closeModalFunc}
              variant="secondary"
            ></tds-button>
            <tds-button
              size="sm"
              text={t("submit")}
              onClick={handleSubmit}
            ></tds-button>
          </div>
        </span>
      </tds-modal>
    </>
  );
};

export default TopicModal;
