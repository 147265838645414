import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Accordion from "./Accordion.tsx";
import { LoggedInContext } from "../../Context/Context.tsx";
import {
  getAllProjects,
  getAllTopics,
  getProjectsByprojectCode,
  getTopicsByProjectId,
} from "../../Services/Service.tsx";
import mqtt, { MqttClient } from "mqtt";

const MessageExplorer = () => {
  const context = useContext(LoggedInContext);
  const [projWarning, setProjWarning] = useState(false);
  const [projectArr, setProjectArr] = useState([]);
  const emptyStructuredArr = [
    { topicName: "", expanded: false, children: [], fullTopicName: "" },
  ];
  const [topicArr, setTopicArr] = useState(emptyStructuredArr);
  const { t } = useTranslation();

  useEffect(() => {
    getAllProjectsFunc();
  }, [context.session.projectCode]);

  // API call to get all projects
  const getAllProjectsFunc = () => {
    if (context.session.projectCode == "All") {
      getAllProjects()
        .then((data: any) => {
          let projectCheck = data.data.length > 0 ? false : true;
          setProjWarning(projectCheck);
          getAllTopicsFunc();
        })
        .catch((err) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    } else {
      getProjectsByprojectCode(context.session.projectCode)
        .then((data: any) => {
          let projectCheck = data.data.length > 0 ? false : true;
          setProjWarning(projectCheck);
          setProjectArr(data.data);
          getAllTopicsFunc(data.data[0].projectId);
        })
        .catch((err) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    }
  };

  // API call to get all topics
  const getAllTopicsFunc = (projectId = "") => {
    const projectCode = context.session.projectCode;
    if (projectCode == "All") {
      getAllTopics()
        .then((data: any) => {
          setTopicHierarchy(data);
        })
        .catch((err) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    } else if (projectCode != "" && projectCode != null) {
      getTopicsByProjectId(projectId)
        .then(async (data: any) => {
          setTopicHierarchy(data);
        })
        .catch((err) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    }
  };

  const setTopicHierarchy = async (data) => {
    let finalStructuredList = [];
    for (let res of data.data) {
      let dataObj = {};
      let parentChild = res.topicName.split("/");
      //checking if the same parent exists
      const foundItem = finalStructuredList.find(
        (item) => item.topicName === parentChild[0]
      );
      if (foundItem == undefined) {
        dataObj["fullTopicName"] = res.topicName;
        dataObj["topicName"] = parentChild[0];
        dataObj["expanded"] = true;
        dataObj["isDirectory"] = true;
        if (parentChild.length > 1) {
          let child = await getChildren(
            parentChild,
            1,
            foundItem,
            res.topicName
          );
          dataObj["children"] = child;
          finalStructuredList.push(dataObj);
        } else {
          dataObj["children"] = [];
          finalStructuredList.push(dataObj);
        }
      } else {
        let child = await getChildren(parentChild, 1, foundItem, res.topicName);
      }
    }
    setTopicArr([...finalStructuredList]);
  };

  //recurrsive array to set the children
  const getChildren = async (parentChild, index, sameParent, fullTopic) => {
    let childArr = [];
    let childObj = {};
    childObj["fullTopicName"] = fullTopic;
    childObj["topicName"] = parentChild[index];
    childObj["expanded"] = true;
    childObj["isDirectory"] = false;
    if (index < parentChild.slice(1).length) {
      let child = await getChildren(parentChild, ++index, undefined, fullTopic);
      childObj["children"] = child;
    } else {
      childObj["children"] = [];
    }
    childArr.push(childObj);
    //condition added to check if the data has same parent then the children are updated
    if (sameParent) {
      sameParent["children"] = [...sameParent["children"], ...childArr];
    }
    return childArr;
  };

  return (
    <>
      {projWarning ? (
        <div>
          <tds-message
            variant="warning"
            header={t("no-project-assigned-info")}
          ></tds-message>
        </div>
      ) : (
        ""
      )}
      <div className="tds-u-flex tds-u-flex-dir-row gap10 tds-col-max-12 tds-col-xxlg-12 tds-col-xlg-12 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12">
        <div className="tds-u-flex tds-u-flex-dir-col gap10 tds-col-max-7 tds-col-xxlg-7 tds-col-xlg-7 tds-col-lg-7 tds-col-md-7 tds-col-sm-7 tds-col-xs-7">
          {/* Hierarchy */}
          <div className="page-background-white hierarchy-css">
            <div className="tree-hierarchy">
              <div>
                <Accordion data={topicArr} />
              </div>
            </div>
          </div>
          {/* Metrics */}
          <div className="page-background-white">{t("metrics")}</div>
        </div>
        <div className="tds-u-flex tds-u-flex-dir-col gap10 tds-col-max-5 tds-col-xxlg-5 tds-col-xlg-5 tds-col-lg-5 tds-col-md-5 tds-col-sm-5 tds-col-xs-5">
          {/* Topic */}
          <div className="page-background-white">{t("topic")}</div>
          {/* Value */}
          <div className="page-background-white">{t("value")}</div>
          {/* History */}
          <div className="page-background-white">{t("history")}</div>
        </div>
      </div>
    </>
  );
};

export default MessageExplorer;
