import React from "react";

// Context to store the user details
export const LoggedInContext = React.createContext({
  session: {
    username:null,
    userRole: null,
    projectCode:null,
    loggedin: false
  },
  setSession: () => {},
});
