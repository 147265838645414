import React, { useState, useEffect, useContext, useRef } from "react";
import { updateClient, createClient } from "../../Services/Service.tsx";
import { sha256, sha224 } from "js-sha256";
import { LoggedInContext } from "../../Context/Context.tsx";
import { passwordPattern } from "../../Constants.tsx";
import { useTranslation } from "react-i18next";

const ClientUserModal = ({
  formDataObj,
  showModal,
  closeModal,
  getAllClientsFunc,
  getTopicsFunc,
  projectArr,
  topicArr,
  projectIdBasedOnRole,
  showErrorModal,
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(formDataObj);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [changePasswordError, setChangePasswordError] = useState("");
  const [changeOldPasswordError, setChangeOldPasswordError] = useState("");
  const [validationError, setValidationError] = useState("");
  const specialCharsRegex = /^(?![0-9_])[A-Za-z0-9_]+$/;
  const context = useContext(LoggedInContext);
  const [showChangePasswordTemplate, setShowChangePasswordTemplate] =
    useState(false);
  const [newAndOldMatchPassword, setNewAndOldMatchPassword] = useState("");
  const [passwordValidationError, setPasswordValidationError] = useState("");
  const [newPasswordValidationError, setNewPasswordValidationError] =
    useState("");
  const [topicIds, setTopicIds] = useState([]);
  const [multiselectKey, setMultiselectKey] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewConfirmPassword, setShowNewConfirmPassword] = useState(false);

  const formDataChangePasswordObj = {
    OldPassword: { value: "", isChanged: false },
    changeNewPassword: { value: "", isChanged: false },
    changeConfirmPassword: { value: "", isChanged: false },
  };
  const [changePasswordObj, setChangePasswordObj] = useState(
    formDataChangePasswordObj
  );

  const closeModalFunc = () => {
    setMultiselectKey((prev) => prev + 1);
    setTopicIds([]);
    closeModal();
  };

  useEffect(() => {}, [topicIds]);

  useEffect(() => {
    setMultiselectKey((prev) => prev + 1);
    setFormData(formDataObj);
    setShowPassword(false);
    setShowConfirmPassword(false);
    setShowOldPassword(false);
    setShowNewPassword(false);
    setShowNewConfirmPassword(false);
    return () => {
      setShowChangePasswordTemplate(false);
      setChangePasswordObj(formDataChangePasswordObj);
      setPasswordError("");
      setChangePasswordError("");
      setNewPasswordValidationError("");
      setPasswordValidationError("");
    };
  }, [formDataObj]);

  const handleInputChange = (event: { target: { name: any; value: any } }) => {
    const { name, value } = event.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: { value: value, isChanged: true },
    }));
    if (name == "confirmPassword") {
      setConfirmPassword(value);
    }
    if (name == "clientUsername") {
      if (!specialCharsRegex.test(value)) {
        setValidationError(t("clientname-specialchar-error"));
      } else {
        setValidationError("");
      }
    }
    if (name == "password") {
      passwordValidation(value);
    }
  };

  const handleDropDownChange = (event) => {
    const { name, value } = event.detail;
    setFormData((prevData) => ({
      ...prevData,
      [name]: { value: value, isChanged: true },
    }));
  };

  const handleTopicChange = (event) => {
    let releaseArr = [];
    if (event.detail.value) {
      releaseArr = event.detail.value.split(",");
      setTopicIds(releaseArr);
    } else {
      setTopicIds(releaseArr);
    }
  };

  const passwordValidation = (value: string) => {
    if (
      formData.newOrEdit?.value != "edit" &&
      value != "" &&
      !passwordPattern.test(value)
    ) {
      setPasswordValidationError(t("paswd-validation-error"));
      return false;
    } else {
      setPasswordValidationError("");
      return true;
    }
  };

  const newPasswordValidation = (value: string) => {
    if (value != "" && !passwordPattern.test(value)) {
      setNewPasswordValidationError(t("paswd-validation-error"));
      return false;
    } else {
      setNewPasswordValidationError("");
      return true;
    }
  };

  const handleInputChangeForChangePassword = (event: {
    target: { name: any; value: any };
  }) => {
    const { name, value } = event.target;
    if (name == "changeNewPassword") {
      setNewAndOldMatchPassword("");
      newPasswordValidation(value);
    }
    setChangePasswordObj((prevData) => ({
      ...prevData,
      [name]: { value: value, isChanged: true },
    }));
  };

  const showChangePasswordTemplateFunc = () => {
    setShowChangePasswordTemplate(true);
  };

  // function to check old password and new password match
  const oldAndNewPasswordMatch = () => {
    if (!changePasswordObj.OldPassword.value) {
      setChangeOldPasswordError(t("old-paswrd-error"));
      return false;
    }
    if (changePasswordObj.OldPassword.value) {
      if (
        formData.password.value !=
        sha256(changePasswordObj.OldPassword.value + "slat_foo123")
      ) {
        setChangeOldPasswordError(t("paswrd-nomatch"));
        return false;
      }
      if (
        changePasswordObj.OldPassword.value ==
        changePasswordObj.changeNewPassword.value
      ) {
        setNewAndOldMatchPassword(t("old-new-same-paswd"));
        return false;
      } else {
        setChangeOldPasswordError("");
        return true;
      }
    }
  };

  const changePaaswordCondFunc = (changePaaswordCond: boolean) => {
    if (changePaaswordCond) {
      return (
        oldAndNewPasswordMatch() &&
        changePasswordObj.changeNewPassword.value &&
        newPasswordValidation(changePasswordObj.changeNewPassword.value)
      );
    } else {
      return true;
    }
  };

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setPasswordError("");
    setChangePasswordError("");
    setNewPasswordValidationError("");
    setPasswordValidationError("");
    const clientUsernameHasError =
      specialCharsRegex.test(formData.clientUsername.value) ||
      formData.clientUsername.value === "";

    if (!clientUsernameHasError) {
      setValidationError(t("clientname-specialchar-error"));
    } else {
      setValidationError("");
    }

    if (!clientUsernameHasError) {
      return;
    }

    if (topicIds.length !== 0) {
      formData["topicId"]["value"] = topicIds.join(",");
      formData["topicId"]["isChanged"] = true;
    }

    const updatedFormData = {};
    const updatedChangePasswordFormData = {};
    for (let key in formData) {
      if (formData.hasOwnProperty(key)) {
        updatedFormData[key] = {
          value: formData[key]["value"],
          isChanged: true,
        };
      }
    }
    setFormData(updatedFormData);
    for (let key in changePasswordObj) {
      if (changePasswordObj.hasOwnProperty(key)) {
        updatedChangePasswordFormData[key] = {
          value: changePasswordObj[key]["value"],
          isChanged: true,
        };
      }
    }

    setFormData(updatedFormData);
    setChangePasswordObj(updatedChangePasswordFormData);
    let passwordCond =
      formData.authType.value == "basic" ||
      formData.authType.value == "clientCertificate"
        ? formData.password.value && passwordValidation(formData.password.value)
        : true;
    // condition to check if change password fields are shown
    let changePaaswordCond =
      formData.newOrEdit?.value == "edit" && showChangePasswordTemplate;

    if (
      formData.clientUsername.value &&
      formData.projectId.value &&
      formData.topicId.value &&
      formData.access.value &&
      formData.authType.value &&
      passwordCond &&
      changePaaswordCondFunc(changePaaswordCond)
    ) {
      // below conditions are to show error message for confirm passwords fields both in normal and change password template
      if (!formData.confirmPassword.value && !showChangePasswordTemplate) {
        setPasswordError(t("confim-paswd-error"));
      } else if (
        !showChangePasswordTemplate &&
        formData.password.value !== formData.confirmPassword.value
      ) {
        setPasswordError(t("confirm-paswd-nomatch"));
      } else if (
        changePaaswordCond &&
        !changePasswordObj.changeConfirmPassword.value
      ) {
        setChangePasswordError(t("confim-paswd-error"));
      } else if (
        changePaaswordCond &&
        changePasswordObj.changeConfirmPassword.value !=
          changePasswordObj.changeNewPassword.value
      ) {
        setChangePasswordError(t("confirm-paswd-nomatch"));
      } else {
        let clientData = {
          clientUsername: formData.clientUsername.value,
          projectId: formData.projectId.value,
          topicId: formData.topicId.value,
          access: formData.access.value,
          authType: formData.authType.value,
        };
        // below conditions are to set value for password and confirm password fields based on scenarios
        if (formData.newOrEdit?.value == "edit") {
          clientData = {
            ...clientData,
            password: !showChangePasswordTemplate
              ? formData.password.value
              : sha256(
                  changePasswordObj.changeNewPassword.value + "slat_foo123"
                ),
            confirmPassword: showChangePasswordTemplate
              ? sha256(
                  changePasswordObj.changeConfirmPassword.value + "slat_foo123"
                )
              : formData.confirmPassword.value,
          };
        }
        clientData = {
          ...clientData,
          password: sha256(formData.password.value + "slat_foo123"),
          confirmPassword: sha256(
            formData.confirmPassword.value + "slat_foo123"
          ),
        };
        if (formData.newOrEdit?.value == "edit") {
          const existingClientData = {
            ...clientData,
            updatedBy: context.session.username,
            clientId: formData.clientId.value,
            changePassword: showChangePasswordTemplate ? true : false,
            status: true,
          };
          //API call for update an existing role
          updateClient(existingClientData)
            .then((data: any) => {
              closeModal();
              setTopicIds([]);
              if (context.session.projectCode != "All") {
                getTopicsFunc(projectIdBasedOnRole);
              } else {
                getAllClientsFunc();
              }
            })
            .catch((err) => {
              if (err?.response?.status == 401) {
                context.setSession({
                  ["username"]: "",
                  ["loggedin"]: false,
                  ["projectCode"]: [],
                  ["userRole"]: "",
                });
                localStorage.clear();
                window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
              }
            });
        } else {
          const newClientData = {
            ...clientData,
            createdBy: context.session.username,
            status: true,
            changePassword: false,
          };
          // API call for create new role
          createClient(newClientData)
            .then((data: any) => {
              closeModal();
              setTopicIds([]);
              if (context.session.projectCode != "All") {
                getTopicsFunc(projectIdBasedOnRole);
              } else {
                getAllClientsFunc();
              }
            })
            .catch((error: any) => {
              if (error?.response?.status == 401) {
                context.setSession({
                  ["username"]: "",
                  ["loggedin"]: false,
                  ["projectCode"]: [],
                  ["userRole"]: "",
                });
                localStorage.clear();
                window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
              }
              if (error?.response?.data?.status == 500) {
                showErrorModal(
                  `${t("client")} ${newClientData.clientUsername} ${t(
                    "exists"
                  )}`
                );
              }
            });
        }
      }
    } else {
      console.log("error");
    }
  };

  return (
    <>
      <tds-modal
        selector="#client-modal"
        id="modal-prog-client"
        size="xs"
        actions="static"
        prevent="true"
        ref={(element) =>
          element?.addEventListener("tdsClose", (event) => {
            closeModalFunc();
          })
        }
      >
        <span slot="body" style={{ paddingRight: "0px !important" }}>
          <div className="tds-u-justify-center tds-u-flex tds-u-p0 tds-u-mb2 tds-col-max-12 tds-col-xxlg-12 tds-col-xlg-12  tds-col-lg-12  tds-col-md-12  tds-col-sm-12 tds-col-xs-12 tds-u-textalign-start">
            <h5 className="tds-u-mt0">
              {formData.newOrEdit?.value == "edit"
                ? t("edit-client")
                : t("create-client")}
            </h5>
          </div>

          <div className="tds-u-mb2 tds-u-mt1 tds-col-max-12 tds-col-xxlg-12 tds-col-xlg-12  tds-col-lg-12  tds-col-md-12  tds-col-sm-12 tds-col-xs-12 tds-u-textalign-start ">
            <tds-text-field
              type="text"
              size="sm"
              label={t("client-name")}
              placeholder={t("enter-client-name")}
              disabled={formData.newOrEdit?.value == "edit"}
              label-position="outside"
              value={formData.clientUsername.value}
              onInput={handleInputChange}
              name="clientUsername"
              helper={
                formData.clientUsername.isChanged &&
                !formData.clientUsername.value
                  ? t("client-name-error")
                  : ""
              }
              state={
                validationError ||
                (formData.clientUsername.isChanged &&
                  !formData.clientUsername.value)
                  ? "error"
                  : "success"
              }
            ></tds-text-field>
          </div>

          <div className="tds-u-mb2 tds-col-max-12 tds-col-xxlg-12 tds-col-xlg-12  tds-col-lg-12  tds-col-md-12  tds-col-sm-12 tds-col-xs-12 tds-u-textalign-start">
            <tds-dropdown
              default-value={formData?.projectId?.value}
              key={formData?.projectId?.value}
              name="projectId"
              disabled={formData?.newOrEdit?.value == "edit"}
              label={t("project")}
              label-position="outside"
              placeholder={t("select-option")}
              ref={(element) =>
                element?.addEventListener("tdsChange", (event: any) => {
                  handleDropDownChange(event);
                })
              }
              helper={
                !formData?.projectId?.value && formData?.projectId?.isChanged
                  ? t("select-project-error")
                  : ""
              }
              size="sm"
              open-direction="auto"
              error={
                !formData?.projectId?.value && formData?.projectId?.isChanged
              }
            >
              {projectArr &&
                projectArr.map((project) => (
                  <tds-dropdown-option value={project.projectId}>
                    {project.projectName}
                  </tds-dropdown-option>
                ))}
            </tds-dropdown>
          </div>

          <div className="tds-u-mb2 tds-col-max-12 tds-col-xxlg-12 tds-col-xlg-12  tds-col-lg-12  tds-col-md-12  tds-col-sm-12 tds-col-xs-12 tds-u-textalign-start">
            <tds-dropdown
              multiselect
              key={multiselectKey}
              name="topicId"
              default-value={formData?.topicId?.value}
              disabled={
                formData.newOrEdit?.value == "edit" ||
                formData.projectId.value == ""
              }
              label={t("topic")}
              label-position="outside"
              placeholder={t("select-option")}
              ref={(element) =>
                element?.addEventListener("tdsChange", (event: any) => {
                  handleTopicChange(event);
                })
              }
              helper={
                formData.topicId.isChanged && !formData.topicId.value
                  ? t("select-topic-error")
                  : ""
              }
              size="sm"
              open-direction="auto"
              error={!formData?.topicId?.value && formData?.topicId?.isChanged}
            >
              {topicArr &&
                topicArr.map((topic) => (
                  <tds-dropdown-option value={topic.topicId}>
                    {topic.topicName}
                  </tds-dropdown-option>
                ))}
            </tds-dropdown>
          </div>

          <div className="tds-u-mb2 tds-col-max-12 tds-col-xxlg-12 tds-col-xlg-12  tds-col-lg-12  tds-col-md-12  tds-col-sm-12 tds-col-xs-12 tds-u-textalign-start">
            <tds-dropdown
              default-value={formData.access.value}
              key={formData.access.value}
              name="access"
              label={t("access")}
              label-position="outside"
              placeholder={t("select-option")}
              ref={(element) =>
                element?.addEventListener("tdsChange", (event: any) => {
                  handleDropDownChange(event);
                })
              }
              helper={
                formData.access.isChanged && !formData.access.value
                  ? t("access-error")
                  : ""
              }
              size="sm"
              open-direction="auto"
              error={!formData?.access?.value && formData?.access?.isChanged}
            >
              <tds-dropdown-option value="publish">
                {t("publish")}
              </tds-dropdown-option>
              <tds-dropdown-option value="subscribe">
                {t("subscribe")}
              </tds-dropdown-option>
              <tds-dropdown-option value="publishSubscribe">
                {t("pub-sub")}
              </tds-dropdown-option>
            </tds-dropdown>
          </div>

          <div className="tds-u-mb2 tds-col-max-12 tds-col-xxlg-12 tds-col-xlg-12  tds-col-lg-12  tds-col-md-12  tds-col-sm-12 tds-col-xs-12 tds-u-textalign-start">
            <tds-dropdown
              default-value={formData.authType.value}
              key={formData.authType.value}
              name="authType"
              label={t("authentication-type")}
              label-position="outside"
              placeholder={t("select-option")}
              ref={(element) =>
                element?.addEventListener("tdsChange", (event: any) => {
                  handleDropDownChange(event);
                })
              }
              helper={
                formData.authType.isChanged && !formData.authType.value
                  ? t("auth-type-error")
                  : ""
              }
              size="sm"
              open-direction="auto"
              error={
                !formData?.authType?.value && formData?.authType?.isChanged
              }
            >
              <tds-dropdown-option value="basic">
                {t("basic-auth")}
              </tds-dropdown-option>
              <tds-dropdown-option value="clientCertificate">
                {t("client-cert")}
              </tds-dropdown-option>
            </tds-dropdown>
          </div>

          {formData.authType.value == "basic" ||
          formData.authType.value == "clientCertificate" ? (
            <>
              <div className="tds-u-mb2 tds-u-mt1 tds-col-max-12 tds-col-xxlg-12 tds-col-xlg-12  tds-col-lg-12  tds-col-md-12  tds-col-sm-12 tds-col-xs-12 tds-u-textalign-start ">
                <tds-text-field
                  type={
                    formData?.newOrEdit?.value == "edit"
                      ? "password"
                      : showPassword
                      ? "text"
                      : "password"
                  }
                  size="sm"
                  label={t("password")}
                  placeholder={t("enter-password")}
                  label-position="outside"
                  disabled={formData?.newOrEdit?.value == "edit"}
                  value={formData.password.value}
                  onInput={handleInputChange}
                  name="password"
                  helper={
                    formData.password.isChanged && !formData.password.value
                      ? t("password-error")
                      : passwordValidationError
                  }
                  state={
                    (formData.password.isChanged && !formData.password.value) ||
                    passwordValidationError
                      ? "error"
                      : "success"
                  }
                >
                  <tds-icon
                    slot="suffix"
                    name="eye"
                    size="16px"
                    style={
                      formData?.newOrEdit?.value == "edit"
                        ? { cursor: "default" }
                        : { cursor: "pointer" }
                    }
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </tds-text-field>
              </div>
              {formData.newOrEdit?.value != "edit" ? (
                <div className="tds-u-mb2 tds-u-mt1 tds-col-max-12 tds-col-xxlg-12 tds-col-xlg-12  tds-col-lg-12  tds-col-md-12  tds-col-sm-12 tds-col-xs-12 tds-u-textalign-start ">
                  <tds-text-field
                    type={showConfirmPassword ? "text" : "password"}
                    size="sm"
                    label={t("confirm-paswd")}
                    placeholder={t("enter-confirm-paswd")}
                    label-position="outside"
                    disabled={
                      formData.newOrEdit?.value == "edit" &&
                      formData.basicOrClient?.value == "basic"
                    }
                    value={formData.confirmPassword.value}
                    onInput={handleInputChange}
                    name="confirmPassword"
                    helper={passwordError}
                    state={passwordError ? "error" : "success"}
                  >
                    <tds-icon
                      slot="suffix"
                      name="eye"
                      size="16px"
                      style={
                        formData?.newOrEdit?.value == "edit"
                          ? { cursor: "default" }
                          : { cursor: "pointer" }
                      }
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    />
                  </tds-text-field>
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
          {formData.newOrEdit?.value == "edit" &&
          !showChangePasswordTemplate ? (
            <div className="tds-u-mb2 tds-u-mt1 tds-col-max-12 tds-col-xxlg-12 tds-col-xlg-12  tds-col-lg-12  tds-col-md-12  tds-col-sm-12 tds-col-xs-12 tds-u-textalign-start ">
              <tds-button
                size="sm"
                text={t("change-paswd")}
                onClick={showChangePasswordTemplateFunc}
              ></tds-button>
            </div>
          ) : (
            ""
          )}
          {showChangePasswordTemplate ? (
            <>
              <div className="tds-u-mb2 tds-u-mt1 tds-col-max-12 tds-col-xxlg-12 tds-col-xlg-12  tds-col-lg-12  tds-col-md-12  tds-col-sm-12 tds-col-xs-12 tds-u-textalign-start ">
                <tds-text-field
                  type={showOldPassword ? "text" : "password"}
                  size="sm"
                  label={t("old-paswd")}
                  placeholder={t("enter-old-paswd")}
                  label-position="outside"
                  value={changePasswordObj.OldPassword.value}
                  onInput={handleInputChangeForChangePassword}
                  name="OldPassword"
                  helper={
                    changePasswordObj.OldPassword.isChanged &&
                    !changePasswordObj.OldPassword.value
                      ? changeOldPasswordError
                      : ""
                  }
                  state={
                    (changePasswordObj.OldPassword.isChanged &&
                      !changePasswordObj.OldPassword.value) ||
                    changeOldPasswordError
                      ? "error"
                      : "success"
                  }
                >
                  <tds-icon
                    slot="suffix"
                    name="eye"
                    size="16px"
                    style={
                      formData?.newOrEdit?.value == "edit"
                        ? { cursor: "default" }
                        : { cursor: "pointer" }
                    }
                    onClick={() => setShowOldPassword(!showOldPassword)}
                  />
                </tds-text-field>
              </div>

              <div className="tds-u-mb2 tds-u-mt1 tds-col-max-12 tds-col-xxlg-12 tds-col-xlg-12  tds-col-lg-12  tds-col-md-12  tds-col-sm-12 tds-col-xs-12 tds-u-textalign-start ">
                <tds-text-field
                  type={showNewPassword ? "text" : "password"}
                  size="sm"
                  label={t("new-paswd")}
                  placeholder={t("enter-new-paswd")}
                  label-position="outside"
                  value={changePasswordObj.changeNewPassword.value}
                  onInput={handleInputChangeForChangePassword}
                  name="changeNewPassword"
                  helper={
                    changePasswordObj.changeNewPassword.isChanged &&
                    !changePasswordObj.changeNewPassword.value
                      ? t("new-paswd-error")
                      : newPasswordValidationError
                      ? newPasswordValidationError
                      : newAndOldMatchPassword
                      ? newAndOldMatchPassword
                      : ""
                  }
                  state={
                    (changePasswordObj.changeNewPassword.isChanged &&
                      !changePasswordObj.changeNewPassword.value) ||
                    newAndOldMatchPassword ||
                    newPasswordValidationError
                      ? "error"
                      : "success"
                  }
                >
                  <tds-icon
                    slot="suffix"
                    name="eye"
                    size="16px"
                    style={
                      formData?.newOrEdit?.value == "edit"
                        ? { cursor: "default" }
                        : { cursor: "pointer" }
                    }
                    onClick={() => setShowNewPassword(!showNewPassword)}
                  />
                </tds-text-field>
              </div>

              <div className="tds-u-mb2 tds-u-mt1 tds-col-max-12 tds-col-xxlg-12 tds-col-xlg-12  tds-col-lg-12  tds-col-md-12  tds-col-sm-12 tds-col-xs-12 tds-u-textalign-start ">
                <tds-text-field
                  type={showNewConfirmPassword ? "text" : "password"}
                  size="sm"
                  label={t("confirm-paswd")}
                  placeholder={t("enter-confirm-paswd")}
                  label-position="outside"
                  value={changePasswordObj.changeConfirmPassword.value}
                  onInput={handleInputChangeForChangePassword}
                  name="changeConfirmPassword"
                  helper={changePasswordError}
                  state={changePasswordError ? "error" : "success"}
                >
                  <tds-icon
                    slot="suffix"
                    name="eye"
                    size="16px"
                    style={
                      formData?.newOrEdit?.value == "edit"
                        ? { cursor: "default" }
                        : { cursor: "pointer" }
                    }
                    onClick={() =>
                      setShowNewConfirmPassword(!showNewConfirmPassword)
                    }
                  />
                </tds-text-field>
              </div>
            </>
          ) : (
            ""
          )}
        </span>
        <span slot="actions">
          <div className="tds-u-flex tds-u-gap1 tds-col-max-12 tds-col-xxlg-12 tds-col-xlg-12  tds-col-lg-12  tds-col-md-12  tds-col-sm-12 tds-col-xs-12">
            <tds-button
              data-dismiss-modal
              size="sm"
              text={t("cancel")}
              onClick={closeModalFunc}
              variant="secondary"
            ></tds-button>
            <tds-button
              size="sm"
              text={t("submit")}
              onClick={handleSubmit}
            ></tds-button>
          </div>
        </span>
      </tds-modal>
    </>
  );
};

export default ClientUserModal;
