import React, { useState, useContext, useEffect } from "react";
import Topic from "./Topic.tsx";
import "../App.css";
import ClientUser from "./ClientUser.tsx";
import { LoggedInContext } from "../Context/Context.tsx";
import Rule from "./Rule.tsx";
import CreateRule from "./CreateRule.tsx";
import { useTranslation } from "react-i18next";

const Dashboard = ({ selectedMenu, loadPage }) => {
  const { t } = useTranslation();
  const context = useContext(LoggedInContext);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [openCreateRulePage, setOpenCreateRulePage] = useState(false);
  const [ruleData, setRuleData] = useState();
  const tabArray = [t("topic"), t("client"), t("rule-engine")];

  const openCreateRule = (menu, data) => {
    setRuleData(data);
    let page = menu == "createRule" ? true : false;
    setOpenCreateRulePage(page);
    loadPage(menu);
  };

  const tabSelectedFunc = (tabIndex) => {
    setSelectedIndex(tabIndex);
  };

  return (
    <>
      <tds-inline-tabs
        selected-index={selectedIndex}
        mode-variant="secondary"
        style={{ background: "white" }}
      >
        {tabArray.map((tab, index) => {
          return (
            <tds-inline-tab>
              <button onClick={() => tabSelectedFunc(index)}>{tab}</button>
            </tds-inline-tab>
          );
        })}
      </tds-inline-tabs>
      <div className="tds-u-p2">
        {selectedIndex == 0 ? (
          <Topic />
        ) : selectedIndex == 1 ? (
          <ClientUser />
        ) : selectedIndex == 2 && !openCreateRulePage ? (
          <Rule openCreateRule={openCreateRule} />
        ) : (
          <CreateRule openCreateRule={openCreateRule} ruleData={ruleData} />
        )}
      </div>
    </>
  );
};

export default Dashboard;
