import React, { useState, useEffect, useMemo, useContext, useRef } from "react";
import ClientUserModal from "./Modals/ClientUserModal.tsx";
import {
  getAllClients,
  actDeactClient,
  getAllProjects,
  getAllTopics,
  getProjectsByprojectCode,
  getTopicsByProjectId,
  getClientsByProjectId,
  deleteClient,
} from "../Services/Service.tsx";
import ResponseModal from "./Modals/ResponseModal.tsx";
import Pagination from "../Components/Pagination.tsx";
import ConfirmationModal from "./Modals/ConfirmationModal.tsx";
import { LoggedInContext } from "../Context/Context.tsx";
import { useTranslation } from "react-i18next";
import { PageSize } from "../Constants.tsx";

const ClientUser = () => {
  const { t } = useTranslation();
  const sortableTable = useRef<HTMLTdsTableElement>(null);
  const disabled = { disabled: true };

  let formDataInit = {
    clientUsername: { value: "", isChanged: false },
    projectId: { value: "", isChanged: false },
    topicId: { value: "", isChanged: false },
    authType: { value: "", isChanged: false },
    access: { value: "", isChanged: false },
    password: { value: "", isChanged: false },
    confirmPassword: { value: "", isChanged: false },
    clientId: { value: "", isChanged: false },
  };

  const [showModal, setShowModal] = useState(false);
  const [formDataObj, setFormDataObj] = useState(formDataInit);
  const [selectedClientId, setSelectedClientId] = useState("");
  const [clientArr, setClientArr] = useState([]);
  const [filterClientArr, setFilterClientArr] = useState([]);
  const [projectArr, setProjectArr] = useState([]);
  const [topicArr, setTopicArr] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [warningMessage, setWarningMessage] = useState("");
  const [actDeactOrDelete, setActDeactOrDelete] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const context = useContext(LoggedInContext);
  const [projectIdBasedOnRole, setProjectIdBasedOnRole] = useState([]);
  const [searchClientVal, setSearchClientVal] = useState("");
  const [clientNameVal, setClientNameVal] = useState("");
  const [actDeactStatus, setActDeactStatus] = useState("");
  const [projWarning, setProjWarning] = useState(false);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return clientArr.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, clientArr]);

  // Handling search input
  const handleInputChange = (event) => {
    const { value } = event.target;
    setSearchClientVal(value);
    let filterArray = filterClientArr.filter((client: any) =>
      client.clientUsername.toLowerCase().includes(value.toLowerCase())
    );
    setClientArr(filterArray);
  };

  // Function to activate/deactivate client user
  const handleCheckboxChange = (
    status,
    clientId: Number,
    clientName: string
  ) => {
    setActDeactOrDelete("actDeact");
    setSelectedClientId(clientId);
    setClientNameVal(clientName);
    setActDeactStatus(status);
    if (status) {
      setWarningMessage(
        `${t("deactive-client")} "${
          clientArr[clientArr.findIndex((x) => x.clientId == clientId)]
            .clientUsername
        }" ?`
      );
    } else {
      setWarningMessage(
        `${t("active-client")} "${
          clientArr[clientArr.findIndex((x) => x.clientId == clientId)]
            .clientUsername
        }" ?`
      );
    }
    (
      document.querySelector(
        `[selector="#show-confirmation-modal"]`
      ) as HTMLTdsModalElement
    ).showModal();
  };

  const confirmedFunc = () => {
    (
      document.querySelector(
        `[selector="#show-confirmation-modal"]`
      ) as HTMLTdsModalElement
    ).closeModal();
    if (actDeactOrDelete == "actDeact") {
      actDeactClient(selectedClientId)
        .then((data: any) => {
          if (actDeactStatus) {
            setResponseMessage(
              `${t("client")} ${clientNameVal} ${t("deactive-success")}`
            );
          } else {
            setResponseMessage(
              `${t("client")} ${clientNameVal} ${t("active-success")}`
            );
          }
          (
            document.querySelector(
              `[selector="#response-message-modal"]`
            ) as HTMLTdsModalElement
          ).showModal();
          if (context.session.projectCode != "All") {
            getTopicsFunc(projectIdBasedOnRole);
          } else {
            getAllClientsFunc();
          }
        })
        .catch((err: any) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    } else {
      deleteClient(selectedClientId)
        .then((data: any) => {
          setResponseMessage(
            `${t("client")} ${clientNameVal} ${t("delete-success")}`
          );
          (
            document.querySelector(
              `[selector="#response-message-modal"]`
            ) as HTMLTdsModalElement
          ).showModal();
          if (context.session.projectCode != "All") {
            getTopicsFunc(projectIdBasedOnRole);
          } else {
            getAllClientsFunc();
          }
        })
        .catch((err: any) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    }
  };

  // Show modal on error
  const showErrorModal = (message) => {
    (
      document.querySelector(
        `[selector="#client-modal"]`
      ) as HTMLTdsModalElement
    ).closeModal();
    setResponseMessage(message);
    (
      document.querySelector(
        `[selector="#response-message-modal"]`
      ) as HTMLTdsModalElement
    ).showModal();
  };

  // Function to create new client
  const addNew = () => {
    setFormDataObj((prevData) => ({
      ...formDataInit,
      ["projectId"]: { value: projectIdBasedOnRole, isChanged: true },
    }));
    setShowModal(!showModal);
    (
      document.querySelector(
        `[selector="#client-modal"]`
      ) as HTMLTdsModalElement
    ).showModal();
  };

  // Function to close Create or Edit modal
  const closeModal = () => {
    (
      document.querySelector(
        `[selector="#client-modal"]`
      ) as HTMLTdsModalElement
    ).closeModal();
  };

  const editClient = (clientId) => {
    let selectedClient =
      clientArr[clientArr.findIndex((x) => x.clientId == clientId)];
    let selectedClientDetails = {};
    for (let key in formDataInit) {
      selectedClientDetails[key] = {
        value: selectedClient[key],
        isChanged: false,
      };
    }

    setFormDataObj((prevData) => ({
      ...selectedClientDetails,
      ["newOrEdit"]: { value: "edit" },
      ["basicOrClient"]: { value: selectedClientDetails.authType.value },
    }));
    setShowModal(!showModal);
    (
      document.querySelector(
        `[selector="#client-modal"]`
      ) as HTMLTdsModalElement
    ).showModal();
  };

  // Function to send selected client details for delete to Confirmation modal
  const deleteClientFunc = (clientId: any, clientName: string) => {
    setSelectedClientId(clientId);
    setActDeactOrDelete("delete");
    setClientNameVal(clientName);
    setWarningMessage(
      `${t("delete-client")} "${
        clientArr[clientArr.findIndex((x) => x.clientId == clientId)]
          .clientUsername
      }" ?`
    );
    (
      document.querySelector(
        `[selector="#show-confirmation-modal"]`
      ) as HTMLTdsModalElement
    ).showModal();
  };

  // API call to get all clients
  const getAllClientsFunc = async (topicIdArr = []) => {
    // *** Modification Start ***
    // Reset the client arrays
    setClientArr([]);
    setFilterClientArr([]);
    // *** Modification End ***

    const projectCode = context.session.projectCode;
    if (projectCode == "All") {
      getAllClients()
        .then((data: any) => {
          setClientArr(data.data);
          setFilterClientArr(data.data);
        })
        .catch((err) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    } else if (projectCode != "" && projectCode != null) {
      let projectId = await getProjectsByprojectCode(
        context.session.projectCode
      )
        .then((data: any) => {
          return data.data[0].projectId;
        })
        .catch((err) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
      let tempClientArr = await getClientsByProjectId(projectId)
        .then((data: any) => {
          return data.data;
        })
        .catch((err) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
      let flatClientArr = flattenArray(tempClientArr);
      setClientArr(flatClientArr);
      setFilterClientArr(flatClientArr);
    }
    setCurrentPage(1);
  };

  function flattenArray(arr) {
    return arr.reduce(
      (acc, val) =>
        Array.isArray(val) ? acc.concat(flattenArray(val)) : acc.concat(val),
      []
    );
  }

  // API call to get all projects
  const getAllProjectsFunc = () => {
    if (context.session.projectCode == "All") {
      getAllProjects()
        .then((data: any) => {
          let projectCheck = data.data.length > 0 ? false : true;
          setProjWarning(projectCheck);
          setProjectArr(data.data);
          getTopicsFunc();
        })
        .catch((err) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    } else {
      getProjectsByprojectCode(context.session.projectCode)
        .then((data) => {
          let projectCheck = data.data.length > 0 ? false : true;
          setProjWarning(projectCheck);
          setProjectIdBasedOnRole(data.data[0].projectId);
          setProjectArr(data.data);
          getTopicsFunc(data.data[0].projectId);
        })
        .catch((err) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    }
  };

  const getTopicsFunc = (projectId = "") => {
    if (context.session.projectCode == "All") {
      getAllTopics()
        .then((data) => {
          setTopicArr(data.data);
          getAllClientsFunc();
        })
        .catch((err) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    } else {
      getTopicsByProjectId(projectId)
        .then((data: any) => {
          let tempTopicIdArr = [];
          setTopicArr(data.data);
          data.data.map((response: any) => {
            tempTopicIdArr.push(response.topicId);
          });
          getAllClientsFunc(tempTopicIdArr);
        })
        .catch((err) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    }
  };

  useEffect(() => {
    // *** Modification Start ***
    // Reset search input when project code changes
    setSearchClientVal("");
    // *** Modification End ***
    getAllProjectsFunc();
  }, [context.session.projectCode]);

  const getProjectName = (projectId: any) => {
    let val = projectArr.findIndex((x) => x.projectId == projectId);
    return val > -1 ? projectArr[val].projectName : "";
  };

  const getTopicName = (topicId: any) => {
    let topicNames: any = [];
    let topics = "";
    if (topicId.includes(",")) {
      const array = topicId.split(",");
      array.forEach((element) => {
        let val = topicArr.findIndex((x) => x.topicId == element);
        topicNames.push(val > -1 ? topicArr[val].topicName : "");
      });
      topics =
        topicNames.join(", ").length > 25
          ? topicNames.join(", ").substring(0, 25) + "..."
          : topicNames.join(", ");
      return { topics: topics, topicsTooltip: topicNames };
    }
    let val = topicArr.findIndex((x) => x.topicId == topicId);
    return {
      topics: val > -1 ? topicArr[val].topicName : "",
      topicsTooltip: "",
    };
  };

  // For handling the sorting
  useEffect(() => {
    // sorting function for table
    const handleSortEvent = (event: Event) => {
      if (event?.detail.columnKey == "clientUsername") {
        if (event.detail.sortingDirection == "asc") {
          let ascSortArray = clientArr
            .slice()
            .sort((first, second) =>
              first.clientUsername.localeCompare(second.clientUsername)
            );
          setClientArr(ascSortArray);
        } else {
          let descSortArray = clientArr
            .slice()
            .sort((first, second) =>
              second.clientUsername.localeCompare(first.clientUsername)
            );
          setClientArr(descSortArray);
        }
      }
      if (event?.detail.columnKey == "access") {
        if (event.detail.sortingDirection == "asc") {
          let ascSortArray = clientArr
            .slice()
            .sort((first, second) => first.access.localeCompare(second.access));
          setClientArr(ascSortArray);
        } else {
          let descSortArray = clientArr
            .slice()
            .sort((first, second) => second.access.localeCompare(first.access));
          setClientArr(descSortArray);
        }
      }
      if (event?.detail.columnKey == "authType") {
        if (event.detail.sortingDirection == "asc") {
          let ascSortArray = clientArr
            .slice()
            .sort((first, second) =>
              first.authType.localeCompare(second.authType)
            );
          setClientArr(ascSortArray);
        } else {
          let descSortArray = clientArr
            .slice()
            .sort((first, second) =>
              second.authType.localeCompare(first.authType)
            );
          setClientArr(descSortArray);
        }
      }
    };

    const sortableTableElement = sortableTable?.current;

    if (sortableTableElement) {
      sortableTableElement.addEventListener("tdsSort", handleSortEvent);
    }

    return () => {
      if (sortableTableElement) {
        sortableTableElement.removeEventListener("tdsSort", handleSortEvent);
      }
    };
  });

  return (
    <>
      <div className="tds-u-flex tds-u-items-center tds-u-justify-between tds-col-max-12 tds-col-xxlg-12 tds-col-xlg-12 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12">
        <div className="tds-u-textalign-start tds-col-max-auto">
          <tds-text-field
            type="text"
            size="lg"
            placeholder={t("search-client")}
            value={searchClientVal}
            onInput={(event) => handleInputChange(event)}
          >
            <span slot="prefix">
              <tds-icon name="search" size="16px"></tds-icon>
            </span>
          </tds-text-field>
        </div>
        {projWarning ? (
          <div>
            <tds-message
              variant="warning"
              header={t("no-project-assigned-info")}
            ></tds-message>
          </div>
        ) : (
          ""
        )}
        {context.session.userRole != "Viewer" ? (
          <div className="tds-u-textalign-end">
            <tds-button
              type="button"
              variant="primary"
              size="sm"
              text={t("create-client")}
              mode-variant="primary"
              onClick={addNew}
              {...(projectArr.length == 0 ? disabled : "")}
            ></tds-button>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="tds-u-textalign-start tds-u-pr2 tds-u-pt2 tds-u-pb3 tds-col-max-12 tds-col-xxlg-12 tds-col-xlg-12 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12">
        <tds-table
          ref={sortableTable}
          vertical-dividers="false"
          compact-design="false"
          responsive="true"
        >
          <tds-table-header>
            <tds-header-cell
              cell-key="clientUsername"
              cell-value={t("client-name")}
              sortable="true"
            ></tds-header-cell>
            <tds-header-cell
              cell-key="project"
              cell-value={t("project")}
            ></tds-header-cell>
            <tds-header-cell
              cell-key="topic"
              cell-value={t("topic")}
            ></tds-header-cell>
            <tds-header-cell
              cell-key="access"
              cell-value={t("access")}
              sortable="true"
            ></tds-header-cell>
            <tds-header-cell
              cell-key="authType"
              cell-value={t("auth-type")}
              sortable="true"
            ></tds-header-cell>
            {context.session.userRole != "Viewer" ? (
              <tds-header-cell
                cell-key="action"
                cell-value={t("action")}
              ></tds-header-cell>
            ) : (
              ""
            )}
          </tds-table-header>
          <tds-table-body>
            {currentTableData &&
              currentTableData.map((value, index) => (
                <tds-table-body-row key={value.clientId}>
                  <tds-body-cell
                    cell-value={value.clientUsername}
                    cell-key="clientUsername"
                    className="tds-u-textalign-start"
                  ></tds-body-cell>
                  <tds-body-cell
                    cell-value={getProjectName(value.projectId)}
                    cell-key="project"
                    className="tds-u-textalign-start"
                  ></tds-body-cell>
                  <tds-body-cell
                    cell-value={getTopicName(value.topicId).topics}
                    cell-key="topic"
                    id={`tooltip-topic-name-${index}`}
                    className="tds-u-textalign-start"
                  >
                    {getTopicName(value.topicId).topics.length > 25 && (
                      <tds-tooltip
                        placement="right-start"
                        text={getTopicName(value.topicId).topicsTooltip}
                        selector={`#tooltip-topic-name-${index}`}
                      />
                    )}
                  </tds-body-cell>

                  <tds-body-cell
                    cell-value={
                      value.access == "publishSubscribe"
                        ? "Publish and Subscribe"
                        : value.access == "publish"
                        ? "Publish"
                        : value.access == "subscribe"
                        ? "Subscribe"
                        : ""
                    }
                    cell-key="access"
                    className="tds-u-textalign-start"
                  ></tds-body-cell>
                  <tds-body-cell
                    cell-value={
                      value.authType == "clientCertificate"
                        ? "Client Certificate"
                        : value.authType == "basic"
                        ? "Basic Authentication"
                        : ""
                    }
                    cell-key="authType"
                    className="tds-u-textalign-start"
                  ></tds-body-cell>
                  {context.session.userRole != "Viewer" ? (
                    <tds-body-cell cell-key="actions">
                      <div className="tds-u-flex tds-u-gap1 h20">
                        <tds-button
                          size="sm"
                          type="button"
                          variant="primary"
                          onClick={() => editClient(value.clientId)}
                          {...(value.status == "deactive" ? disabled : "")}
                        >
                          <tds-icon
                            slot="icon"
                            size="16px"
                            name="edit"
                          ></tds-icon>
                        </tds-button>

                        <tds-button
                          size="sm"
                          type="button"
                          variant="danger"
                          onClick={() =>
                            deleteClientFunc(
                              value.clientId,
                              value.clientUsername
                            )
                          }
                        >
                          <tds-icon
                            slot="icon"
                            size="16px"
                            name="trash"
                          ></tds-icon>
                        </tds-button>

                        <div className="tds-toggle sc-tds-toggle sc-tds-toggle-h sc-tds-toggle-s">
                          <input
                            onChange={() =>
                              handleCheckboxChange(
                                value.status == "active",
                                value.clientId,
                                value.clientUsername
                              )
                            }
                            className="lg sc-tds-toggle toggle-input"
                            type="checkbox"
                            name="actDeactive"
                            role="switch"
                            id={`toggle-${index}`}
                            checked={value.status == "active"}
                          />
                          <label
                            className="sc-tds-toggle sc-tds-toggle-s"
                            htmlFor={`toggle-${index}`}
                          >
                            <div slot="label" className="sc-tds-toggle"></div>
                          </label>
                        </div>
                      </div>
                    </tds-body-cell>
                  ) : (
                    ""
                  )}
                </tds-table-body-row>
              ))}
          </tds-table-body>
        </tds-table>

        <div className="pagination-wrapper">
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={clientArr.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>

      <ClientUserModal
        formDataObj={formDataObj}
        showModal={showModal}
        closeModal={closeModal}
        getAllClientsFunc={getAllClientsFunc}
        getTopicsFunc={getTopicsFunc}
        projectArr={projectArr}
        topicArr={topicArr}
        projectIdBasedOnRole={projectIdBasedOnRole}
        showErrorModal={showErrorModal}
      />
      <ResponseModal message={responseMessage} />
      <ConfirmationModal
        message={warningMessage}
        confirmedFunc={confirmedFunc}
      />
    </>
  );
};

export default ClientUser;
