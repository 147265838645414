import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const ConfirmationModal = ({ message, confirmedFunc }) => {
  const { t } = useTranslation();

  const okFunc = () => {
    confirmedFunc();
  };

  return (
    <>
      <tds-modal
        className="confirmationModal"
        header=""
        selector="#show-confirmation-modal"
        id="confirmationMessageModal"
        size="xs"
        actions="static"
        prevent="true"
      >
        <span slot="body" className="tds-u-flex tds-u-justify-center">
          {message}
        </span>
        <span slot="actions" className="floatRight">
          <div className="flexBtn">
            <tds-button
              data-dismiss-modal
              size="md"
              variant="secondary"
              text={t("no")}
              type="button"
            ></tds-button>
            <tds-button
              data-dismiss-modal
              size="md"
              text={t("yes")}
              type="button"
              onClick={okFunc}
            ></tds-button>
          </div>
        </span>
      </tds-modal>
    </>
  );
};

export default ConfirmationModal;
