import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const ResponseModal = ({ message }) => {
  const { t } = useTranslation();

  return (
    <>
      <tds-modal
        className="deleteSuccessModal"
        header=""
        selector="#response-message-modal"
        id="responseMessageModal"
        size="xs"
        actions="static"
        prevent="true"
      >
        <span slot="body">{message}</span>
        <span slot="actions" className="floatRight">
          <div className="flexBtn">
            <tds-button
              data-dismiss-modal
              size="md"
              text={t("ok")}
              type="button"
            ></tds-button>
          </div>
        </span>
      </tds-modal>
    </>
  );
};

export default ResponseModal;
