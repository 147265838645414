import React, { useState, useEffect, useMemo, useContext, useRef } from "react";
import { PageSize } from "../Constants.tsx";
import { LoggedInContext } from "../Context/Context.tsx";
import { useTranslation } from "react-i18next";
import Pagination from "../Components/Pagination.tsx";
import {
  deleteRuleById,
  getAllRules,
  getAllProjects,
  getProjectsByprojectCode,
  getRulesByProjectCode,
} from "../Services/Service.tsx";
import ResponseModal from "./Modals/ResponseModal.tsx";
import ConfirmationModal from "./Modals/ConfirmationModal.tsx";

const Rule = ({ openCreateRule }) => {
  const { t } = useTranslation();
  const context = useContext(LoggedInContext);
  const disabled = { disabled: true };
  const [currentPage, setCurrentPage] = useState(0);
  const [ruleArr, setRuleArr] = useState([]);
  const [projectCode, setProjectCode] = useState(null);
  const [selectedRuleId, setSelectedRuleId] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [modeforResponseMessageModal, setModeforResponseMessageModal] =
    useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const sortableTable = useRef<HTMLTdsTableElement>(null);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return ruleArr.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, ruleArr]);

  const getAllRulesFunc = () => {
    if (context.session.projectCode == "All") {
      getAllRules()
        .then((data) => {
          setRuleArr(data.data);
          setCurrentPage(1);
        })
        .catch((err) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    } else {
      getRulesByProjectCode(context.session.projectCode)
        .then((data) => {
          setRuleArr(data.data);
          setCurrentPage(1);
        })
        .catch((err) => {
          if (err?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    }
  };

  // API call to get all the projects
  const getAllProjectsFunc = () => {
    if (context.session.projectCode == "All") {
      getAllProjects().then((data: any) => {
        setProjectCode(data.data);
      });
    } else {
      getProjectsByprojectCode(context.session.projectCode).then((data) => {
        setProjectCode(data.data);
      });
    }
  };

  useEffect(() => {
    getAllRulesFunc();
    getAllProjectsFunc();
  }, [ruleArr.length, context.session.projectCode]);

  useEffect(() => {
    // Sorting function for table
    const handleSortEvent = (event: Event) => {
      if (event?.detail.columnKey == "id") {
        if (event.detail.sortingDirection == "asc") {
          let ascSortArray = ruleArr
            .slice()
            .sort((first, second) =>
              first.id.toString().localeCompare(second.id.toString())
            );
          setRuleArr(ascSortArray);
        } else {
          let descSortArray = ruleArr
            .slice()
            .sort((first, second) =>
              second.id.toString().localeCompare(first.id.toString())
            );
          setRuleArr(descSortArray);
        }
      }
    };

    const sortableTableElement = sortableTable?.current;

    if (sortableTableElement) {
      sortableTableElement.addEventListener("tdsSort", handleSortEvent);
    }

    return () => {
      if (sortableTableElement) {
        sortableTableElement.removeEventListener("tdsSort", handleSortEvent);
      }
    };
  });

  const addNew = () => {
    openCreateRule("createRule", "");
  };

  const editRule = (data) => {
    openCreateRule("createRule", data);
  };

  const deleteRuleFunc = (ruleId: any) => {
    setSelectedRuleId(ruleId);
    setWarningMessage(
      `${t("delete-rule")} "${
        ruleArr[ruleArr.findIndex((x) => x.id == ruleId)].id
      }" ?`
    );
    (
      document.querySelector(
        `[selector="#show-confirmation-modal"]`
      ) as HTMLTdsModalElement
    ).showModal();
  };

  const confirmedDeleteFunc = () => {
    (
      document.querySelector(
        `[selector="#show-confirmation-modal"]`
      ) as HTMLTdsModalElement
    ).closeModal();
    setModeforResponseMessageModal("");
    deleteRuleById(selectedRuleId)
      .then((data: any) => {
        if (data?.data?.http_status == 409) {
          setResponseMessage(
            `${t("rule-delete-msg")} ${selectedRuleId} ${t(
              "rule-delete-error"
            )}`
          );
        } else {
          setResponseMessage(
            `${t("rule-delete-msg")} ${selectedRuleId} ${t("delete-success")}`
          );
        }
        (
          document.querySelector(
            `[selector="#response-message-modal"]`
          ) as HTMLTdsModalElement
        ).showModal();
        getAllRulesFunc();
      })
      .catch((err: any) => {
        if (err?.response?.status == 401) {
          context.setSession({
            ["username"]: "",
            ["loggedin"]: false,
            ["projectCode"]: [],
            ["userRole"]: "",
          });
          localStorage.clear();
          window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
        }
      });
  };

  return (
    <>
      <div className="tds-u-flex tds-u-items-center tds-u-justify-between tds-col-max-12 tds-col-xxlg-12 tds-col-xlg-12 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12 tds-u-mb2">
        {/* Empty div to balance the flex container */}
        <div className="tds-u-textalign-start tds-col-max-auto"></div>

        {/* Warning message */}
        {projectCode == 0 ? (
          <tds-message
            variant="warning"
            header={t("no-project-assigned-info")}
          ></tds-message>
        ) : (
          ""
        )}

        {/* Create Rule Button */}
        {context.session.userRole != "mqtt_mgmt_projectuser" ? (
          <div className="tds-u-textalign-end">
            <tds-button
              type="button"
              variant="primary"
              size="sm"
              text={t("create-rule")}
              mode-variant="primary"
              onClick={addNew}
              {...(projectCode == 0 ? disabled : "")}
            ></tds-button>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="tds-u-textalign-start tds-u-pr2 tds-u-pt2 tds-u-pb3 tds-col-max-12 tds-col-xxlg-12 tds-col-xlg-12 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12">
        <tds-table
          ref={sortableTable}
          vertical-dividers="false"
          compact-design="false"
          responsive="true"
        >
          <tds-table-header>
            <tds-header-cell
              cell-key="id"
              cell-value={t("rule-id")}
              sortable="true"
            ></tds-header-cell>
            <tds-header-cell
              cell-key="from"
              cell-value={t("rule-source")}
            ></tds-header-cell>
            <tds-header-cell
              cell-key="enable"
              cell-value={t("rule-enable")}
            ></tds-header-cell>
            <tds-header-cell
              cell-key="description"
              cell-value={t("rule-note")}
            ></tds-header-cell>
            <tds-header-cell
              cell-key="actions"
              cell-value={t("rule-action")}
            ></tds-header-cell>
            {context.session.userRole != "mqtt_mgmt_projectuser" ? (
              <tds-header-cell
                cell-key="rule-actions"
                cell-value={t("rule-actions")}
              ></tds-header-cell>
            ) : (
              ""
            )}
          </tds-table-header>
          <tds-table-body>
            {currentTableData &&
              currentTableData.map((value, index) => (
                <tds-table-body-row key={value.id}>
                  <tds-body-cell
                    cell-value={value.id}
                    cell-key="id"
                    className="tds-u-textalign-start"
                  ></tds-body-cell>
                  <tds-body-cell
                    cell-value={value.from[0]}
                    cell-key="from"
                    className="tds-u-textalign-start"
                  ></tds-body-cell>
                  <tds-body-cell
                    cell-value={JSON.stringify(value.enable)}
                    cell-key="enable"
                    className="tds-u-textalign-start"
                  ></tds-body-cell>
                  <tds-body-cell
                    cell-value={value.description}
                    cell-key="description"
                    className="tds-u-textalign-start"
                  ></tds-body-cell>
                  <tds-body-cell
                    cell-value={value.actions.length}
                    cell-key="actions"
                    className="tds-u-textalign-start"
                  ></tds-body-cell>
                  {context.session.userRole != "mqtt_mgmt_projectuser" ? (
                    <tds-body-cell cell-key="rule-actions">
                      <div className="tds-u-flex tds-u-gap1 h20">
                        <tds-button
                          size="sm"
                          type="button"
                          variant="primary"
                          onClick={() => editRule(value)}
                        >
                          <tds-icon
                            slot="icon"
                            size="16px"
                            name="edit"
                          ></tds-icon>
                        </tds-button>

                        <tds-button
                          size="sm"
                          type="button"
                          variant="danger"
                          onClick={() => deleteRuleFunc(value.id)}
                        >
                          <tds-icon
                            slot="icon"
                            size="16px"
                            name="trash"
                          ></tds-icon>
                        </tds-button>
                      </div>
                    </tds-body-cell>
                  ) : (
                    ""
                  )}
                </tds-table-body-row>
              ))}
          </tds-table-body>
        </tds-table>

        <div className="pagination-wrapper">
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={ruleArr.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>

      <ResponseModal
        message={responseMessage}
        mode={modeforResponseMessageModal}
      />
      <ConfirmationModal
        message={warningMessage}
        confirmedFunc={confirmedDeleteFunc}
      />
    </>
  );
};

export default Rule;
