import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App.tsx";
import "./register-webcomponents.ts";
import { AzureAD } from "react-aad-msal";
import { authProvider } from "./authProvider.tsx";
import "./i18n/config.tsx";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AzureAD provider={authProvider} forceLogin={true}>
    <App />
  </AzureAD>
);
