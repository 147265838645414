import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { getProjectDetails, getUserDetails } from "../Services/Service.tsx";
import { Link } from "react-router-dom";

const Header = ({
  isDesktop,
  mobileMenuClick,
  logoutFunc,
  session,
  username,
  selectedProjectFunc,
}) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [projectArr, setProjectArr] = useState([]);
  const [showAvatarMenu, setShowAvatarMenu] = useState(false);
  const [openAppLauncher, setOpenAppLauncher] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState();
  const refElement = useRef(null);
  const refElement1 = useRef(null);
  const [selectedProjectCode, setSelectedProjectCode] = useState("");
  const [dummy, setDummy] = useState(false); // New dummy state for forcing re-render

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click occurred outside the element
      if (refElement.current && !refElement.current.contains(event.target)) {
        setShowAvatarMenu(false);
      }
      if (refElement1.current && !refElement1.current.contains(event.target)) {
        setOpenAppLauncher(false);
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const changeLanguage = (langCode: any) => {
    i18n.changeLanguage(langCode);
    setDummy((prev) => !prev); // Toggle dummy state to force re-render
  };

  const logout = () => {
    logoutFunc();
  };

  const openUrl = (url: any) => {
    window.location.replace(url);
    //toggleMobileNav();
  };
  const openExternalUrl = (url: any) => {
    window.open(url);
    //toggleMobileNav();
  };

  useEffect(() => {
    if (username) {
      getProjectDetailFunc();
    }
  }, [username, projectArr.length]);

  const getProjectDetailFunc = () => {
    getProjectDetails(username)
      .then((data) => {
        if (data.data.length > 0) {
          selectedProjectFunc(data.data[0]);
          setProjectArr(data.data);
          setSelectedProjectId(data.data[0].projectUserId);
          setSelectedProjectCode(data.data[0].projectCode);
        } else {
          getUserDetails(username).then((data) => {
            if (data.data.length > 0 && data.data[0].superAdmin) {
              setProjectArr([{ projectCode: "All" }]);
              selectedProjectFunc("All");
              setSelectedProjectCode("All");
            } else {
              setProjectArr([{ projectCode: "" }]);
              selectedProjectFunc("");
              setSelectedProjectCode("");
            }
          });
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <tds-header key={dummy}>
      <tds-header-hamburger
        onClick={() => {
          toggleMobileNav();
        }}
        aria-label="Open application drawer"
        aria-haspopup="true"
        aria-expanded="false"
      ></tds-header-hamburger>

      <tds-header-title>
        <div className="header-title-container">
          <Link
            onClick={() => openUrl(process.env.REACT_APP_IOT_APP_URL)}
            className="title-iot"
            style={{
              textDecoration: "none",
              color: "inherit",
              cursor: "pointer",
            }}
          >
            {t("iot-as-a-service")}
          </Link>
          <span className="title-dashboard">{t("mqtt-management")}</span>
        </div>
      </tds-header-title>

      <div className="tds-u-flex-end" slot="end">
        {projectArr.length > 0 ? (
          <tds-header-dropdown slot="end">
            <span slot="label">{selectedProjectCode}</span>
            <tds-header-dropdown-list>
              {projectArr.map((project) => (
                <tds-header-dropdown-list-item key={project.projectUserId}>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      selectedProjectFunc(project);
                      setSelectedProjectCode(project.projectCode);
                    }}
                  >
                    <div className="tds-u-inline-block tds-u-pt2">
                      {project.projectCode}
                    </div>
                  </a>
                </tds-header-dropdown-list-item>
              ))}
            </tds-header-dropdown-list>
          </tds-header-dropdown>
        ) : (
          ""
        )}
      </div>

      <tds-header-dropdown onClick={() => {}} slot="end" no-dropdown-icon>
        <div slot="icon">
          <tds-icon size="28px" name="global"></tds-icon>
        </div>
        <tds-header-dropdown-list size="md">
          <tds-header-dropdown-list-item
            style={{ cursor: "pointer" }}
            onClick={() => {
              changeLanguage("en");
            }}
          >
            <div className="tds-u-inline-block tds-u-pt2">English</div>
          </tds-header-dropdown-list-item>
          <tds-header-dropdown-list-item
            style={{ cursor: "pointer" }}
            onClick={() => {
              changeLanguage("zh");
            }}
          >
            <div className="tds-u-inline-block tds-u-pt2">简体中文</div>
          </tds-header-dropdown-list-item>
          <tds-header-dropdown-list-item
            style={{ cursor: "pointer" }}
            onClick={() => {
              changeLanguage("pt");
            }}
          >
            <div className="tds-u-inline-block tds-u-pt2">Português</div>
          </tds-header-dropdown-list-item>
        </tds-header-dropdown-list>
      </tds-header-dropdown>

      <tds-header-launcher slot="end" aria-label="Application launcher">
        <tds-header-launcher-grid-title>
          {t("iot-services")}
        </tds-header-launcher-grid-title>
        <tds-header-launcher-grid>
          <tds-header-launcher-grid-item>
            <Link onClick={() => openUrl(process.env.REACT_APP_MQTT_URL)}>
              <tds-icon name="filters" size="32"></tds-icon>
              {t("mqtt-manager")}
            </Link>
          </tds-header-launcher-grid-item>
          <tds-header-launcher-grid-item>
            <Link
              onClick={() =>
                openUrl(process.env.REACT_APP_ASSET_MANAGEMENT_URL)
              }
            >
              <tds-icon name="folder" size="32"></tds-icon>
              {t("asset-modelling")}
            </Link>
          </tds-header-launcher-grid-item>
          <tds-header-launcher-grid-item>
            <Link
              onClick={() => openUrl(process.env.REACT_APP_SELF_SERVICE_URL)}
            >
              <tds-icon name="report" size="32"></tds-icon>
              {t("dashboards")}
            </Link>
          </tds-header-launcher-grid-item>
        </tds-header-launcher-grid>
        <tds-header-launcher-grid-title>
          {t("external-services")}
        </tds-header-launcher-grid-title>
        <tds-header-launcher-grid>
          <tds-header-launcher-grid-item>
            <Link
              onClick={() =>
                openExternalUrl(process.env.REACT_APP_INFLUXDB_URL)
              }
            >
              <tds-icon name="redirect" size="32"></tds-icon>
              {t("influxdb")}
            </Link>
          </tds-header-launcher-grid-item>
          <tds-header-launcher-grid-item>
            <Link
              onClick={() => openExternalUrl(process.env.REACT_APP_GRAFANA_URL)}
            >
              <tds-icon name="redirect" size="32"></tds-icon>
              {t("grafana")}
            </Link>
          </tds-header-launcher-grid-item>
          <tds-header-launcher-grid-item>
            <Link
              onClick={() => openExternalUrl(process.env.REACT_APP_EMQX_URL)}
            >
              <tds-icon name="redirect" size="32"></tds-icon>
              {t("emqx")}
            </Link>
          </tds-header-launcher-grid-item>
        </tds-header-launcher-grid>
      </tds-header-launcher>

      <tds-header-dropdown onClick={() => {}} slot="end" no-dropdown-icon>
        <div slot="icon">
          <img
            src="https://www.svgrepo.com/show/384676/account-avatar-profile-user-6.svg"
            alt="User menu."
          />
        </div>
        <tds-header-dropdown-list size="lg">
          <tds-header-dropdown-list-user
            header={username}
          ></tds-header-dropdown-list-user>
          <tds-header-dropdown-list-item onClick={logoutFunc}>
            <Link href="#" passHref>
              <div style={{ display: "inline-block" }}>
                <tds-icon
                  name="profile_inactive"
                  style={{ display: "inline-block" }}
                ></tds-icon>
                <div className="tds-u-pl1" style={{ display: "inline-block" }}>
                  {t("logout")}
                </div>
              </div>
            </Link>
          </tds-header-dropdown-list-item>
        </tds-header-dropdown-list>
      </tds-header-dropdown>

      <tds-header-brand-symbol slot="end">
        <a aria-label="Scania - red gryphon on blue shield"></a>
      </tds-header-brand-symbol>
    </tds-header>
  );
};

export default Header;
