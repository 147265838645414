import { useState, useMemo } from "react";

// Function to sort the data
const useSortableData = (arr, config = null) => {
  const [sortConfig, setSortConfig] = useState(config);

  //functoin to sort the array
  const sortedItems = useMemo(() => {
    const sortedArray = [...arr];
    if (sortConfig !== null) {
      if (sortConfig.key == "startDate" || sortConfig.key == "endDate") {
        sortedArray.sort((a, b) => {
          let firstValue = new Date(a[sortConfig.key]);
          let secondValue = new Date(b[sortConfig.key]);
          if (firstValue < secondValue) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (firstValue > secondValue) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      } else {
        sortedArray.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
    }
    return sortedArray;
  }, [arr, sortConfig]);

  // function to send value whether ascending pr descending
  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

export default useSortableData;
