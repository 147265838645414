import mqtt from "mqtt";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const AccordionItem = ({ item, children, level = 0, index, parent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState();
  const [showData, setShowData] = useState(false);
  const [selectedTopicName, setSelectedTopicName] = useState("");
  const [connected, setConnected] = useState(false);
  const [client, setClient] = useState(null);
  const { t } = useTranslation();

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const selectedTopic = (fullTopicName) => {
    console.log(fullTopicName);
    setSelectedTopicName(fullTopicName);
    setShowData(!showData);
  };

  useEffect(() => {
    const mqttUrl = "wss://broker.mqtt.devtest.aws.scania.com:8084/mqtt";
    const topic = "IS/cnc_milling_machine"; // The topic to subscribe to
    const options = {
      clientId: "react-client-" + Math.random().toString(16).substr(2, 8), // Unique client ID
      username: "bridge_client",
      password: "Bridge@2023",
      clean: true, // Clean session flag
    };

    if (showData) {
      // Create a client and connect to the EMQX broker over wss://
      const mqttClient = mqtt.connect(mqttUrl, options);

      // Handle successful connection
      mqttClient.on("connect", () => {
        setConnected(true);
        setMessages("");
        console.log("Connected to EMQX broker");
        // Subscribe to the topic after connecting
        mqttClient.subscribe(selectedTopicName, (err) => {
          if (!err) {
            console.log(`Subscribed to topic: ${selectedTopicName}`);
          } else {
            console.error("Error subscribing to topic:", err);
          }
        });
      });

      // Handle incoming messages
      mqttClient.on("message", (selectedTopicName, message) => {
        const msg = message.toString();
        console.log(`Message received on ${selectedTopicName}: ${msg}`);
        setMessages(msg);
      });

      // Handle connection errors
      mqttClient.on("error", (err) => {
        console.error("Connection error:", err);
      });
      setClient(mqttClient);
      // Clean up and disconnect on component unmount
      return () => {
        if (mqttClient) {
          mqttClient.end();
          console.log("Disconnected from EMQX broker");
        }
      };
    }
  }, [showData]);

  return (
    <li className="asset-hierarchy-li">
      <div
        className={`accordion-item ${isOpen ? "open" : ""}`}
        style={{ paddingLeft: `${level * 10}px` }}
      >
        {item.children.length != 0 ? (
          <>
            <span className="arrow" onClick={toggleAccordion}>
              {isOpen ? (
                <tds-icon
                  name="chevron_up"
                  size="16px"
                  style={{ color: "#0D0F13" }}
                ></tds-icon>
              ) : (
                <tds-icon
                  name="chevron_down"
                  size="16px"
                  style={{ color: "#0D0F13" }}
                ></tds-icon>
              )}
            </span>
            <span
              onClick={toggleAccordion}
              className={`header-text ${
                item.isDirectory ? "directory-title" : ""
              }`}
            >
              {item.topicName}
            </span>
          </>
        ) : (
          <>
            <span className="asset-hierarchy-isAsset-dot">•</span>
            <span
              className="asset-hierarchy-isAsset"
              onClick={() => selectedTopic(item.fullTopicName)}
            >
              {item.topicName}
            </span>
          </>
        )}
      </div>
      {isOpen && children && <ul className="nested">{children}</ul>}
      {showData ? (
        <div>
          <tds-textarea
            state="default"
            name="payload"
            label=""
            helper=""
            label-position="outside"
            placeholder={t("no-messages")}
            value={messages}
            no-min-width
            //disabled
          ></tds-textarea>
        </div>
      ) : (
        ""
      )}
    </li>
  );
};

const Accordion = ({ data, level = 1 }) => {
  const renderAccordionItems = (items, currentLevel, parent = "") => {
    return items.map((item, index) => (
      <AccordionItem
        key={index}
        item={item}
        level={currentLevel}
        index={index}
        parent={parent}
      >
        {item.children &&
          renderAccordionItems(item.children, currentLevel + 1, item)}
      </AccordionItem>
    ));
  };

  return <ul className="accordion">{renderAccordionItems(data, level)}</ul>;
};

export default Accordion;
