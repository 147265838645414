import React, { useState, useEffect, useRef } from "react";
import Header from "./Layouts/Header.tsx";
import "./App.css";
import Sidebar from "./Layouts/Sidebar.tsx";
import Footer from "./Layouts/Footer.tsx";
import Dashboard from "./Pages/Dashboard.tsx";
import useMediaQuery from "./Hooks/useMediaQuery.tsx";
import { LoggedInContext } from "./Context/Context.tsx";
import { Buffer } from "buffer";
import eventEmitter from "./EventEmitter.tsx";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { authProvider } from "./authProvider.tsx";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import { useTranslation } from "react-i18next";

const App = () => {
  const { i18n } = useTranslation();
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const [sideMenuOnMob, setSideMenuOnMob] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [username, setUsername] = useState("");
  const [mode, setMode] = useState<"Light" | "Dark">("Light");
  const sideMenuRef = useRef<HTMLTdsSideMenuElement>(null);
  const [loader, setLoader] = useState("false");
  const [session, setSession] = useState({
    loggedin: false,
    username: "",
    userRole: "",
    projectCode: [],
  });

  useEffect(() => {
    eventEmitter.on("loader", (newValue) => {
      setLoader(newValue);
    });
    return () => {
      eventEmitter.removeAllListeners("loader");
    };
  }, [eventEmitter.emit]);

  useEffect(() => {
    const token = localStorage.getItem(
      "msal." + process.env.REACT_APP_AD_CLIENT_ID + ".idtoken"
    );
    let decodedVal = Buffer.from(token, "base64").toString("binary");
    let jsonObj = JSON.parse(decodedVal.split("}")[1] + "}");
    setUsername(jsonObj["preferred_username"]);
    setSession({
      ["username"]: jsonObj["preferred_username"],
      ["loggedin"]: true,
      ["projectCode"]: [],
      ["userRole"]: "",
    });
  }, []);
  const value = { session, setSession };

  const selectedProject = (project: any) => {
    if (project == "All") {
      setSession({
        ["username"]: username,
        ["loggedin"]: true,
        ["projectCode"]: ["All"],
        ["userRole"]: "superadmin",
      });
    } else {
      setSession({
        ["username"]: username,
        ["loggedin"]: true,
        ["projectCode"]: [project.projectCode],
        ["userRole"]: project.role,
      });
    }
  };

  useEffect(() => {
    if (navigator.language.startsWith("pt")) {
      i18n.changeLanguage("pt");
    } else if (navigator.language.startsWith("zh")) {
      i18n.changeLanguage("zh");
    } else {
      i18n.changeLanguage("en");
    }
  }, []);

  const mobileMenuClick = (isOpen: boolean) => {
    setSideMenuOnMob(!isOpen);
  };
  const loadPage = (menu: any) => {
    setSelectedMenu(menu);
  };

  const toggleMobileNav = () => {
    if (sideMenuRef.current) {
      sideMenuRef.current.open = !sideMenuRef.current.open;
    }
  };

  const logoutFunc = (value: string) => {
    setSession({
      ["username"]: "",
      ["loggedin"]: false,
      ["projectCode"]: [],
      ["userRole"]: "",
    });
    localStorage.clear();
    window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
  };

  return (
    <AzureAD provider={authProvider}>
      <BrowserRouter>
        <div className={`App tds-mode-${mode.toLowerCase()}`}>
          <div>
            <Header
              isDesktop={isDesktop}
              mobileMenuClick={mobileMenuClick}
              logoutFunc={logoutFunc}
              session={session.loggedin}
              username={username}
              selectedProjectFunc={selectedProject}
            />

            <div>
              <main className="tds-u-h-100 tds-u-w-100">
                <div
                  className={
                    loader == "true"
                      ? "tds-container-fluid page-min-height opacity-blur tds-u-pl0 tds-u-pr0"
                      : "tds-container-fluid page-min-height tds-u-pl0 tds-u-pr0"
                  }
                >
                  <LoggedInContext.Provider value={value}>
                    <Routes>
                      <Route
                        path="/login"
                        element={<Navigate to="/dashboard" />}
                      />
                      <Route
                        path="/dashboard"
                        element={
                          <Dashboard
                            selectedMenu={selectedMenu}
                            loadPage={loadPage}
                          />
                        }
                      />
                      <Route path="*" element={<Navigate to="/dashboard" />} />
                    </Routes>
                  </LoggedInContext.Provider>
                </div>
                {loader == "true" ? (
                  <div className="loader">
                    <tds-spinner size="lg" variant="standard"></tds-spinner>
                  </div>
                ) : (
                  ""
                )}
              </main>
            </div>
          </div>
        </div>
      </BrowserRouter>
    </AzureAD>
  );
};

export default App;
