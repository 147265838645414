import React, { useState, useEffect, useMemo, useContext } from "react";
import editIcon from "../Assets/Edit.svg";
import trashIcon from "../Assets/Trash.svg";
import { LoggedInContext } from "../Context/Context.tsx";
import { useTranslation } from "react-i18next";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import CreateRuleModal from "./Modals/CreateRuleModal.tsx";
import {
  createRuleApi,
  getActionData,
  updateRuleApi,
} from "../Services/Service.tsx";
import ConfirmationModal from "./Modals/ConfirmationModal.tsx";

const CreateRule = ({ openCreateRule, ruleData }) => {
  const { t } = useTranslation();
  const context = useContext(LoggedInContext);

  let formDataInitRepublish = {
    targetTopic: { value: "", isChanged: false, isValid: true },
    targetQos: { value: "1", isChanged: false, isValid: true },
    targetRetain: { value: "false", isChanged: false, isValid: true },
    payloadTemplate: { value: "", isChanged: false, isValid: true },
  };

  let formDataInitInflux = {
    influxName: { value: "", isChanged: false, isValid: true },
    influxServerHost: {
      value: process.env.REACT_APP_SERVER_HOST_INFLUXDB,
      isChanged: false,
      isValid: true,
    },
    influxDatabase: {
      value:
        context?.session?.projectCode[0] != null
          ? context?.session?.projectCode[0]
          : "",
      isChanged: false,
      isValid: true,
    },
    influxUsername: {
      value: process.env.REACT_APP_INFLUX_USERNAME,
      isChanged: false,
      isValid: true,
    },
    influxPassword: {
      value: process.env.REACT_APP_INFLUX_PASSWORD,
      isChanged: false,
      isValid: true,
    },
    influxMeasurement: { value: "", isChanged: false, isValid: true },
    influxTimestamp: { value: "", isChanged: false, isValid: true },
    fieldKeyValList: {
      value: [
        {
          fieldKey: "",
          fieldVal: "",
          isChanged: false,
          isValid: true,
        },
      ],
    },
    tagKeyValList: {
      value: [
        {
          tagKey: "",
          tagVal: "",
          isChanged: false,
          isValid: true,
        },
      ],
    },
  };

  let formDataInitkafka = {
    kafkaTopic: { value: "", isChanged: false, isValid: true },
    name: { value: "", isChanged: false, isValid: true },
    description: { value: "", isChanged: false, isValid: true },
    messageKey: { value: "", isChanged: false, isValid: true },
    messageVal: { value: "", isChanged: false, isValid: true },
    timestamp: { value: "", isChanged: false, isValid: true },
    kafkaHeaders: { value: "", isChanged: false, isValid: true },
    kafkaHeaderValueEncode: { value: "none", isChanged: false, isValid: true },
    partStrategy: { value: "random", isChanged: false, isValid: true },
    compression: { value: "no_compression", isChanged: false, isValid: true },
    headerKeyValList: {
      value: [
        {
          headerKey: "",
          headerVal: "",
          isChanged: false,
          isValid: true,
        },
      ],
    },
  };

  const [formRepublishData, setFormRepublishData] = useState(
    formDataInitRepublish
  );
  const [formInfluxData, setFormInfluxData] = useState(formDataInitInflux);
  const [formKafkaData, setFormKafkaData] = useState(formDataInitkafka);
  const [ruleDetails, setRuleDetails] = useState(ruleData);
  const [formActionData, setFormActionData] = useState([]);
  const [ruleActionName, setRuleActionName] = useState();
  const [sqlValue, setSqlValue] = useState("");
  const [sqlValueError, setSqlValueError] = useState(false);
  const [actionType, setActionType] = useState([]);
  const [actionSelectedForEdit, setActionSelectedForEdit] = useState();
  const [newOrEdit, setNewOrEdit] = useState("new");
  const [newAction, setNewAction] = useState(false);
  const [isActionEdit, setIsActionEdit] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [descriptionVal, setDescriptionVal] = useState("");

  // Function to open the create action modal
  const createRule = () => {
    setNewAction(true);
    setIsActionEdit(true);
  };

  const closeModal = () => {
    (
      document.querySelector(`[selector="#topic-modal"]`) as HTMLTdsModalElement
    ).closeModal();
  };

  // Function is called when the action modal is closed
  const getConfigurationData = (config: any) => {
    document.getElementById("modal-prog-create-rule").closeModal();
    setIsActionEdit(false);
    if (newOrEdit == "new" || newOrEdit == "newBack") {
      setNewOrEdit("newBack");
    } else {
      setNewOrEdit("editBack");
    }
    let tempActionsArr = [...actionType];
    let tempActFormArr = [...formActionData];
    if (newAction) {
      let newActionArr = [...tempActionsArr, config[0]];
      if (config[0].function == "republish") {
        tempActFormArr.push(JSON.stringify(config[0]));
      } else {
        tempActFormArr.push(config[0]);
      }
      setFormActionData(tempActFormArr);
      setActionType(newActionArr);
    } else {
      let newActionArr = [];
      let newActionArrForm = [];
      if (config[0].function == "republish") {
        tempActionsArr.map((data: any) => {
          if (data.function == "republish") {
            newActionArr.push(config[0]);
            newActionArrForm.push(JSON.stringify(config[0]));
          } else {
            newActionArr.push(data);
            newActionArrForm.push(JSON.stringify(data));
          }
        });
        setFormActionData(newActionArrForm);
        setActionType(newActionArr);
      }
    }
    setFormInfluxData(formDataInitInflux);
    setFormKafkaData(formDataInitkafka);
    setFormRepublishData(formDataInitRepublish);
  };

  // Function called when the create action modal cancel button is clicked
  const cancelModal = () => {
    setIsActionEdit(false);
    document.getElementById("modal-prog-create-rule").closeModal();
    setFormInfluxData(formDataInitInflux);
    setFormKafkaData(formDataInitkafka);
    setFormRepublishData(formDataInitRepublish);
  };

  // Function is called on click of the action's edit button
  const editAction = (selectedAction) => {
    setActionSelectedForEdit(selectedAction);
    setNewAction(false);
    if (selectedAction.function == "republish") {
      setIsActionEdit(true);
      setFormRepublishData({
        targetTopic: {
          value: selectedAction.args.topic,
          isChanged: false,
          isValid: true,
        },
        targetQos: {
          value: selectedAction.args.qos,
          isChanged: false,
          isValid: true,
        },
        targetRetain: {
          value: selectedAction.args.retain,
          isChanged: false,
          isValid: true,
        },
        payloadTemplate: {
          value: selectedAction.args.payload,
          isChanged: false,
          isValid: true,
        },
      });
    } else if (selectedAction.startsWith("kafka")) {
      getActionData(selectedAction)
        .then((data: any) => {
          let headerExtArr = [];
          if (data?.data?.parameters?.hasOwnProperty("kafka_ext_headers")) {
            data?.data?.parameters?.kafka_ext_headers.map((headerVals) => {
              let headerObj = {
                headerKey: headerVals.kafka_ext_header_key,
                headerVal: headerVals.kafka_ext_header_value,
                isChanged: false,
                isValid: true,
              };
              headerExtArr.push(headerObj);
            });
          }
          setIsActionEdit(true);
          setFormKafkaData({
            kafkaTopic: {
              value: data.data.parameters.topic,
              isChanged: false,
              isValid: true,
            },
            name: { value: data.data.name, isChanged: false, isValid: true },
            description: {
              value: data.data.description,
              isChanged: false,
              isValid: true,
            },
            messageKey: {
              value: data.data.parameters.message.key,
              isChanged: false,
              isValid: true,
            },
            messageVal: {
              value: data.data.parameters.message.value,
              isChanged: false,
              isValid: true,
            },
            timestamp: {
              value: data.data.parameters.message.timestamp,
              isChanged: false,
              isValid: true,
            },
            kafkaHeaders: {
              value: data?.data?.parameters?.hasOwnProperty("kafka_headers")
                ? data?.data?.parameters?.kafka_headers
                : "",
              isChanged: false,
              isValid: true,
            },
            kafkaHeaderValueEncode: {
              value: data.data.parameters.kafka_header_value_encode_mode,
              isChanged: false,
              isValid: true,
            },
            partStrategy: {
              value: data.data.parameters.partition_strategy,
              isChanged: false,
              isValid: true,
            },
            compression: {
              value: data.data.parameters.compression,
              isChanged: false,
              isValid: true,
            },
            headerKeyValList: {
              value: headerExtArr,
            },
          });
        })
        .catch((error: any) => {
          if (error?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    } else if (selectedAction.startsWith("influxdb_api_v1")) {
      getActionData(selectedAction)
        .then((data: any) => {
          let splitWriteData = data.data.write_syntax.split(" ");
          let fieldsArr = [];
          let tagsArr = [];
          splitWriteData[1].split(",").map((fields: any) => {
            let tempObj = {
              fieldKey: fields.split("=")[0],
              fieldVal: fields.split("=")[1],
              isChanged: false,
              isValid: true,
            };
            fieldsArr.push(tempObj);
          });
          let tagMeasure = splitWriteData[0].split(",");
          for (let index = 1; index < tagMeasure.length; index++) {
            let tempObj = {
              tagKey: tagMeasure[index].split("=")[0],
              tagVal: tagMeasure[index].split("=")[1],
              isChanged: false,
              isValid: true,
            };
            tagsArr.push(tempObj);
          }
          setIsActionEdit(true);
          setFormInfluxData({
            influxName: {
              value: data.data.name,
              isChanged: false,
              isValid: true,
            },
            influxServerHost: {
              value: data.data.server,
              isChanged: false,
              isValid: true,
            },
            influxDatabase: {
              value: data.data.database,
              isChanged: false,
              isValid: true,
            },
            influxUsername: {
              value: data.data.username,
              isChanged: false,
              isValid: true,
            },
            influxPassword: {
              value: data.data.password,
              isChanged: false,
              isValid: true,
            },
            influxMeasurement: {
              value: splitWriteData[0].split(",")[0],
              isChanged: false,
              isValid: true,
            },
            influxTimestamp: {
              value: splitWriteData[2],
              isChanged: false,
              isValid: true,
            },
            fieldKeyValList: {
              value: fieldsArr,
            },
            tagKeyValList: {
              value: tagsArr,
            },
          });
        })
        .catch((error: any) => {
          if (error?.response?.status == 401) {
            context.setSession({
              ["username"]: "",
              ["loggedin"]: false,
              ["projectCode"]: [],
              ["userRole"]: "",
            });
            localStorage.clear();
            window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
          }
        });
    }
  };

  // Function is called on click of the action's delete button
  const deleteAction = (ruleActionName: any) => {
    setWarningMessage(`${t("delete-action")} ?`);
    setRuleActionName(ruleActionName);
    (
      document.querySelector(
        `[selector="#show-confirmation-modal"]`
      ) as HTMLTdsModalElement
    ).showModal();
  };

  // Function is called after action's delete is confirmed
  const confirmedDeleteActFunc = () => {
    document.getElementById("confirmationMessageModal").closeModal();
    let filteredData = [];
    filteredData = ruleDetails?.actions.filter(
      (actionObj: any) => actionObj != ruleActionName
    );
    setRuleDetails((prev) => ({
      ...ruleDetails,
      ["actions"]: filteredData,
    }));
    let tempArr = [];
    filteredData.map((data: any) => {
      if (data.function == "republish") {
        tempArr.push(JSON.stringify(data));
      } else {
        tempArr.push(data);
      }
    });
    setFormActionData(tempArr);
  };

  // Function to get the sql input fields details
  const handleInputSQLChange = (event) => {
    if (event.trim()) {
      setSqlValueError(false);
    } else {
      setSqlValueError(true);
    }
    setSqlValue(event);
  };

  //useEffect function added just to handle the action modal opening and closing
  useEffect(() => {
    if (isActionEdit) {
      (
        document.querySelector(
          `[selector="#create-rule-modal"]`
        ) as HTMLTdsModalElement
      ).showModal();
    }
  }, [isActionEdit]);

  //Function called when the create action modal cancel button is clicked

  // Function to get the description text field details
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDescriptionVal(value);
  };

  // Function called on click of create/update button
  const handleSubmit = (event) => {
    event.preventDefault();
    sqlValue?.trim() ? setSqlValueError(false) : setSqlValueError(true);
    if (sqlValue?.trim() && !sqlValueError) {
      let inputObj = {
        description: context.session.projectCode + " - " + descriptionVal,
        sql: sqlValue,
        actions: formActionData,
      };
      if (newOrEdit == "new" || newOrEdit == "newBack") {
        // API call for create new rule
        createRuleApi(inputObj)
          .then((data: any) => {
            openCreateRule("rule");
          })
          .catch((error: any) => {
            if (error?.response?.status == 401) {
              context.setSession({
                ["username"]: "",
                ["loggedin"]: false,
                ["projectCode"]: [],
                ["userRole"]: "",
              });
              localStorage.clear();
              window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
            }
          });
      } else {
        inputObj["id"] = ruleDetails.id;
        // API call for updating a rule
        updateRuleApi(inputObj)
          .then((data: any) => {
            openCreateRule("rule");
          })
          .catch((error: any) => {
            if (error?.response?.status == 401) {
              context.setSession({
                ["username"]: "",
                ["loggedin"]: false,
                ["projectCode"]: [],
                ["userRole"]: "",
              });
              localStorage.clear();
              window.open(process.env.REACT_APP_AD_LOGOUT, "_self");
            }
          });
      }
    }
  };

  // UseEffect function for getting the rule details when in edit mode
  useEffect(() => {
    if (ruleDetails != "") {
      setNewOrEdit("edit");
      setSqlValue(ruleDetails.sql);
      setDescriptionVal(ruleDetails.description.split("-")[1]);
      if (ruleDetails?.actions?.length > 0) {
        setActionType(ruleDetails?.actions);
        let tempFormArr = [];
        ruleDetails?.actions?.map((data) => {
          if (data.function == "republish") {
            tempFormArr.push(JSON.stringify(data));
          } else {
            tempFormArr.push(data);
          }
        });
        setFormActionData(tempFormArr);
      } else {
        setActionType([]);
        setFormActionData([]);
      }
    }
  }, [ruleDetails]);

  return (
    <>
      <div className="tds-u-p0 ">
        <div className="tds-row tds-u-p0 ">
          <div className="tds-u-flex tds-col-max-12 tds-col-xxlg-12 tds-col-xlg-12 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12">
            <div className="tds-col-max-5 tds-col-xxlg-5 tds-col-xlg-5 tds-col-lg-5 tds-col-md-5 tds-col-sm-5 tds-col-xs-5">
              <tds-text-field
                state="success"
                size="sm"
                placeholder={t("create-rule-description")}
                className="tds-on-white-bg txt-bold"
                name="createRuleDescription"
                value={descriptionVal}
                onInput={handleInputChange}
                label={t("create-rule-description")}
                label-position="outside"
                style={{ textAlign: "left" }}
              >
                <span slot="prefix" className="prefix-css" disabled="true">
                  {context.session.projectCode} -
                </span>
              </tds-text-field>
            </div>
          </div>
          <div className="tds-u-mt3 tds-u-pr0 tds-u-flex tds-col-max-12 tds-col-xxlg-12 tds-col-xlg-12 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12">
            <div className="tds-u-p0 tds-col-max-8 tds-col-xxlg-8 tds-col-xlg-8 tds-col-lg-8 tds-col-md-8 tds-col-sm-12 tds-col-xs-12">
              <AceEditor
                mode="java"
                theme="github"
                name="SQL"
                fontSize={18}
                highlightActiveLine={true}
                editorProps={{ $blockScrolling: true }}
                showPrintMargin={true}
                showGutter={true}
                enableBasicAutocompletion={true}
                enableLiveAutocompletion={true}
                className="width-100 border-light-grey aceEditorHeight"
                onChange={handleInputSQLChange}
                value={sqlValue}
              />
              {sqlValueError ? (
                <span className="sql-editor-error">
                  {t("required-field-validation")}
                </span>
              ) : (
                ""
              )}
            </div>
            <div className="tds-u-textalign-start tds-u-pr0 tds-col-max-4 tds-col-xxlg-4 tds-col-xlg-4 tds-col-lg-4 tds-col-md-4 tds-col-sm-12 tds-col-xs-12">
              {newOrEdit != "new" && actionType.length > 0
                ? actionType?.map((data: any, index) => (
                    <div className="rule-type-css" key={index}>
                      {data?.function == "republish" ? (
                        <div className="rule-action-view-css tds-u-pr0 tds-col-max-7 tds-col-xxlg-7 tds-col-xlg-7 tds-col-lg-7 tds-col-md-7 tds-col-sm-7 tds-col-xs-7">
                          {t("republish")}
                        </div>
                      ) : (
                        <div className="rule-action-view-css tds-u-p0 tds-u-pr0 tds-col-max-7 tds-col-xxlg-7 tds-col-xlg-7 tds-col-lg-7 tds-col-md-7 tds-col-sm-7 tds-col-xs-7">
                          <div className="rule-action-view-name">
                            {data?.split(":")[1]}
                          </div>
                          <div>{data?.split("_")[0]}</div>
                        </div>
                      )}
                      <div className="div-btn-horz tds-u-flex tds-col-max-5 tds-col-xxlg-5 tds-col-xlg-5 tds-col-lg-5 tds-col-md-5 tds-col-sm-5 tds-col-xs-5 tds-justify-end">
                        <tds-button
                          size="sm"
                          variant="secondary"
                          onClick={() => editAction(data)}
                        >
                          <tds-icon slot="icon" name="edit"></tds-icon>
                        </tds-button>
                        <tds-button
                          size="sm"
                          variant="danger"
                          onClick={() => deleteAction(data)}
                        >
                          <tds-icon slot="icon" name="trash"></tds-icon>
                        </tds-button>
                      </div>
                    </div>
                  ))
                : ""}
              <tds-button
                size="sm"
                variant="primary"
                onClick={createRule}
                text={t("add-action")}
              >
                {" "}
                <tds-icon slot="icon" size="16px" name="plus"></tds-icon>{" "}
              </tds-button>
            </div>
          </div>
          <div className="tds-u-mt3 tds-u-gap1 tds-u-mb3 tds-u-flex tds-col-max-12 tds-col-xxlg-12 tds-col-xlg-12 tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12">
            <tds-button
              size="sm"
              variant="secondary"
              onClick={() => openCreateRule("rule")}
              text={t("cancel")}
            ></tds-button>
            <tds-button
              size="sm"
              variant="primary"
              className="tds-u-ml1"
              onClick={handleSubmit}
              text={
                newOrEdit === "new" || newOrEdit === "newBack"
                  ? t("create")
                  : t("update")
              }
            ></tds-button>
          </div>
        </div>
      </div>
      {isActionEdit ? (
        <CreateRuleModal
          formDataInitRepublish={formRepublishData}
          formDataInitkafka={formKafkaData}
          formDataInitInflux={formInfluxData}
          sendConfigData={getConfigurationData}
          newOrEdit={newOrEdit}
          actionType={actionSelectedForEdit}
          cancelModal={cancelModal}
          isNewAction={newAction}
        />
      ) : (
        ""
      )}
      <ConfirmationModal
        message={warningMessage}
        confirmedFunc={confirmedDeleteActFunc}
      />
    </>
  );
};

export default CreateRule;
